/*@flow*/
import type {
  Map,
  VectorTileSource,
  RasterTileSource,
  RasterDEMTileSource,
  GeoJSONSource,
  ImageSource,
  VideoSource,
} from 'mapbox-gl';
import type { EventMap } from '../';

type DeckT = {|
  id: string,
  type: string,
  config: Object,
  events: EventMap,
|};

class Source {
  deck: DeckT;
  map: Map;
  source:
    | VectorTileSource
    | RasterTileSource
    | RasterDEMTileSource
    | GeoJSONSource
    | ImageSource
    | VideoSource;

  constructor(deck: DeckT, map: Map) {
    this.deck = deck;
    this.map = map;
  }

  enable() {
    const { deck, map } = this;
    if (!map.getSource(deck.id)) {
      map.addSource(deck.id, deck.config);
      this.source = map.getSource(deck.id);
    }

    const bind = o => this.source.on(o[0], o[1]);
    const ev = deck.events || {};
    Object.keys(ev).forEach(k => bind([k, ev[k]]));
  }
  update(newDeck: DeckT) {
    const { deck, map } = this;
    this.source = map.getSource(deck.id);
    if (this.source) {
      // HACK: We don't have direct access to GeoJSONSource class in
      // mapbox-gl module to refine the type of the source, and importing
      // it from src is not possible since the library source code comes with
      // a lot of garbage dependencies.
      if ('setData' in this.source) {
        // $FlowFixMe
        this.source && this.source.setData(newDeck.config.data);
      }
    }
    this.deck = newDeck;
  }

  disable() {
    const { deck } = this;

    const unbind = o => this.source.off(o[0], o[1]);
    const ev = deck.events || {};
    Object.keys(ev).forEach(k => unbind([k, ev[k]]));
  }
}

export default (deck: DeckT, map: Map) => new Source(deck, map);
