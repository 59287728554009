/*@flow*/
import useTitle from 'lib/useTitle';
import PagesIndex from 'comps/Pages/Index';
import * as commands from 'store/commands';
import React, { useEffect } from 'react';
import MapContext from '../Index/MapContext';
import useData from 'lib/useData';
import bizQueryDef from 'queries/Businesses';

type EnhancedPropsT = {|
  decks?: Object[],
|};

const Component = (props: EnhancedPropsT) => {
  useTitle('BrownsvilleNow - Find');
  useEffect(() => commands.setSectionName('Find'), []);
  const [items, isLoading] = useData(bizQueryDef, 'bizPages');

  return (
    <MapContext.Provider value={{ items, isLoading, decks: props.decks || [] }}>
      <PagesIndex />
    </MapContext.Provider>
  );
};

export default Component;
