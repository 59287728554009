/*@flow*/
/** @jsx jsx */
import React from 'react';
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import AsyncImage from './AsyncImage';

const Scroller = styled.div(
  props => `
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  height: inherit;
  ${props.singleImage ? 'justify-content: center;' : ''}

  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space */
    background: transparent; /* optional: just make scrollbar invisible */
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`
);
const Background = styled.div`
  position: relative;
  height: 300px;
  width: 100%;
  margin-bottom: 10px;

  & .btn {
    position: absolute;

    top: 50%;
    transform: translateY(-50%);

    height: 30px;
    width: 30px;

    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.5);
    background-position: 50% 50%;
    background-repeat: no-repeat;

    z-index: 1;

    font-size: 0px;
  }
`;

type InputPropsT = { images: string[] };

const Gallery = (props: InputPropsT) => {
  const ref = React.createRef();
  if (props.images.length === 0) return null;
  const isSingleImage = props.images.length === 1;
  return (
    <Background className="gallery">
      <Scroller ref={ref} singleImage={isSingleImage}>
        {props.images.map((url, i) => (
          <AsyncImage src={url} key={i} width={480} height={290} />
        ))}
      </Scroller>
    </Background>
  );
};

export default Gallery;
