/*@flow*/
/** @jsx jsx */
import { useState, createRef, useEffect, Fragment } from 'react';
import FiltersQueryDef from 'queries/Filters';
import useStoreState from 'lib/useStoreState';
import ApplyBtn from './ApplyBtn';
import { jsx, css } from '@emotion/core';
import * as commands from 'store/commands';
import useRouter from 'lib/useRouter';
import useCachedQuery from 'lib/useCachedQuery';
import * as I from 'immutable';
import styled from '@emotion/styled';

const pluralize = (word, num) => (num === 1 ? word : word + 's');

const KeywordSection = () => {
  const { q } = useStoreState({
    q: 'filters.q',
  });
  const [keywordQuery, setQuery] = useState(q);
  const ref = createRef();
  const timerRef = createRef();

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current);
    if (keywordQuery === q) return;

    timerRef.current = setTimeout(() => {
      commands.search(keywordQuery);
      setTimeout(() => {
        document.body && window.scrollTo(0, window.innerHeight);
      }, 0);
    }, 800);
  }, [keywordQuery]);

  return (
    <div ref={ref} className="mb-3">
      <input
        type="text"
        className="form-control text-center border-top-0 border-left-0 border-right-0 rounded-0"
        placeholder="Enter keywords"
        value={keywordQuery}
        onChange={e => {
          setQuery(e.target.value);
        }}
      />
    </div>
  );
};

function Spinner() {
  return (
    <p
      className="text-center"
      css={css(`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(255, 255, 255, 0.3);
          display: grid;
          align-items: center;
          margin: 0;
        `)}
    >
      <i className="fa fa-spinner fa-spin" />
    </p>
  );
}

const Container = styled.div`
  position: fixed;
  bottom: 0px;
  right: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  background: white;
  padding: 20px 15px;
  box-shadow: 0px -0.2rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-top: solid 1px #dcdcdc;
`;

function useData(queryDef) {
  const { filters, companyId } = useStoreState({
    filters: 'filters',
    companyId: 'companyId',
  });
  const variables = { filters, companyId };
  return useCachedQuery(queryDef, { variables });
}

const Component = () => {
  const { history } = useRouter();
  const { query = {} } = history.location;
  const { data, loading: isLoading } = useData(FiltersQueryDef);
  const value = I.getIn(data, ['company', 'projectCount'], 0);

  useEffect(() => {
    if (typeof value !== 'number') return;
    commands.setResultCount(value);
  }, [value]);

  return (
    <Fragment>
      <div style={{ height: 200 }} />
      <Container>
        <KeywordSection />
        {isLoading && <Spinner />}
        <div className="text-center">
          {value} {pluralize('result', value)} found.
        </div>
        {value > 0 && query.dest && (
          <div style={{ marginTop: 10 }}>
            <ApplyBtn destination={query.dest || ''} />
          </div>
        )}
      </Container>
    </Fragment>
  );
};

export default Component;
