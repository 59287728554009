/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import NonEmptyArray from 'lib/NonEmptyArray';
import { Link } from 'react-router-dom';
import { format, isBefore } from 'date-fns';
import { type AttractionQuery_company_attraction_events } from 'queries/__generated__/AttractionQuery';
type EventsT = AttractionQuery_company_attraction_events;

const Card = v => (
  <div className="mt-2 mb-3">
    <h5
      className="m-0"
      css={css(
        `line-height: 1.5rem;font-size: 1.1rem;font-weight: bold;color: var(--blue);`
      )}
    >
      {v.startsAt ? format(new Date(v.startsAt), 'M/d') : null}
    </h5>
    <Link to={`/pages/${v.id}`} className="d-flex justify-content-between">
      <h5
        className="m-0 pr-2"
        css={css(
          `line-height: 1.5rem;font-size: 1.1rem;font-weight: bold;color: var(--blue);`
        )}
      >
        {v.name}
      </h5>
      <span>
        <i className="fa fa-caret-right" />
      </span>
    </Link>
  </div>
);

export default (props: { events: EventsT[] }) => {
  if (!NonEmptyArray.is(props.events)) return null;

  const cards = [...props.events]
    .sort((a, b) =>
      isBefore(new Date(a.startsAt || ''), new Date(b.startsAt || '')) ? -1 : 1
    )
    .map((v, i) => <Card {...v} key={i} />);

  return (
    <div
      css={css`
        .events > div {
          display: grid;
          grid-template-columns: 30% 1fr;
        }
      `}
      className="pl-3 pr-3"
    >
      <h6
        css={css(
          `font-size: 1.3rem;font-weight: bold;font-family: Avenir-Heavy; color: var(--blue);`
        )}
      >
        Events
      </h6>
      <div className="events pb-2">{cards}</div>
    </div>
  );
};
