/*@flow*/
import { connect } from 'react-redux';
import remap from 'lib/remap';
import { compose, type HOC } from 'recompose';
import store from 'store';
import getIn from 'lib/getIn';
import * as c from './constants';
import truthy_ from 'lib/truthy';

export const con: Object => HOC<*, *> = compose(
  connect,
  remap
);

const setWith = dispatch => (
  path: string,
  value: any,
  options: ?{ f: boolean }
) => dispatch({ type: c.SET_RECORD, payload: { path, value, options } });

export const set = setWith(store.dispatch);

export const dispatch = store.dispatch;

export const state = getIn(store.getState);

export const localStore = {
  get: (_path: string) => {},
  set: (_path: string, _value: any) => {},
};

export const truthy = (o: string) => () => truthy_(state(o));
