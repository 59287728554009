/*@flow*/
import 'lib/fastClick';
import 'lib/prettyPrint';
import 'lib/object-entries';
import 'unfetch/polyfill';
// -------------------------------
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'comps/App';
import * as serviceWorker from './serviceWorker';
// $FlowFixMe
import './index.scss';

const node = document.getElementById('root');

node && ReactDOM.render(<App />, node);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
