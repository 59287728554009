/*@flow*/
import React, { createRef, useState, useEffect } from 'react';
import Atlas from 'comps/App/Atlas';
import styled from '@emotion/styled';

const mkMarkers = arr =>
  arr.map(v => ({
    geometry: {
      type: 'Point',
      coordinates: v.lonlat,
    },
    properties: {
      icon: 'marker-15',
      ...(v.properties || {}),
      id: v.id,
    },
  }));

const Container = styled.div(
  props => `
  position: relative;
  height: ${props.mapHeight || '100vh'};
`
);

// Note: We generalize LonlatT as a union type to support the
// return types automatically generated from GraphQL schema, which
// does not have a notion of tuples yet.
type LonlatT = number[] | [number, number];
type Point = { id: ID, lonlat: LonlatT };
type PropsT = {|
  center: LonlatT,
  points: Point[],
  isLoading?: boolean,
  children?: React$Node,
  height?: string | number,
|};

const ShowMap = (props: PropsT) => {
  const { center, points = [], isLoading, height, children } = props;
  // Create markers for all the points from the parent component.
  const data = mkMarkers(points);
  const decks = [
    {
      id: 'points',
      type: 'geojson',
      data,
      layout: {
        'icon-image': '{icon}',
        'icon-size': 1.5,
        'icon-allow-overlap': true,
        'icon-offset': [0, -11],
      },
    },
  ];

  const config = {
    style: 'mapbox://styles/citiesense/cjri4diol131g2smgh7eft7gg',
    center,
    zoom: 16,
    minZoom: 14,
    attributionControl: false,
  };

  const [isReady, setReady] = useState(false);
  const myRef = createRef();
  useEffect(() => {
    !isReady && myRef.current && setReady(true);
  }, [myRef]);

  return (
    <Container className="map-container" mapHeight={height} ref={myRef}>
      {isReady && (
        <Atlas decks={decks} config={config} loading={Boolean(isLoading)} />
      )}
      {children}
    </Container>
  );
};

export default ShowMap;
