/*@flow*/
import { graphql, type ChildProps } from 'react-apollo';
import { type ButtonStackPropsT } from 'comps/App/ButtonStack';
import mkButtonStackEnhancer from 'comps/App/ButtonStack/mkButtonStackEnhancer';
import { con } from 'store/ops';
import { type DocumentNode } from 'graphql';
import { type Map } from 'immutable';
import { compose, withHandlers, withProps, type HOC } from 'recompose';
import * as I from 'immutable';
import remapProps from 'lib/remapProps';
import { type FiltersQuery } from 'queries/__generated__/FiltersQuery';

export type withQueryProps<T, ItemT = string> = ChildProps<
  {
    ...$Exact<ButtonStackPropsT<ItemT>>,
    filters: Map<string, *>,
    title?: React$Node,
    companyId: ID,
  },
  T
>;

type PropsT = {
  query: DocumentNode,
  selected: string[] | (() => string[]),
  onSelect: (string[], string) => void,
  title: React$Node,
  itemsPath?: string | string[] | (() => string),
  getFilters?: () => Map<string, *>,
  items?: string[] | (() => string[]),
  itemLimit?: number | void,
};

type EnhancedType = HOC<withQueryProps<FiltersQuery>, *>;

export default function withQuery({
  query,
  itemsPath,
  selected,
  onSelect,
  title,
  getFilters,
  items,
  itemLimit,
}: PropsT): EnhancedType {
  return compose(
    withProps({ title }),
    mkButtonStackEnhancer({ selected, onSelect, items, itemLimit }),
    con({
      companyId: 'companyId',
      filters: () => (getFilters ? getFilters() : I.Map<string, *>()),
    }),
    graphql(query),
    remapProps(props => ({ items: [itemsPath, props.items] })),
    withHandlers({
      onMore: ({ items: i2, showMore, update, selected: s2 }) => () => {
        const selectedIxs = s2.map(v => (items: any).indexOf(v)).sort();
        const lastSelectedItemIx = selectedIxs[selectedIxs.length - 1] || 0;
        const numItemsToShow = 4;
        const smallItemLimit = Math.max(lastSelectedItemIx, numItemsToShow);
        update({
          showMore: !showMore,
          itemLimit: showMore ? i2.length : smallItemLimit,
        });
      },
    })
  );
}
