/*@flow*/
import rbush from 'rbush';
import knn from 'lib/georbush';

type IndexAPI = {
  load: <T>(T[]) => void,
  clear: () => IndexAPI,
  orderBy: <T>(number[]) => T,
};
type Props = {
  capacity?: number,
  format?: string[],
  numNeighbors?: number,
  maxDistance?: number,
};

function SpatialIndex({ capacity, format }: Props = {}): IndexAPI {
  // accepts [x, y]
  format = format || ['[0]', '[1]', '[0]', '[1]'];
  capacity = capacity || 100;

  const tree = rbush(capacity, format);

  tree.orderBy = ([x, y]: number[]) => {
    const v = knn(tree, x, y);
    return v;
  };
  return tree;
}
SpatialIndex.create = props => new SpatialIndex(props);

export default SpatialIndex;
