/*@flow*/
import React from 'react';
import FiltersQueryDef from 'queries/Filters';
import { type FiltersQuery } from 'queries/__generated__/FiltersQuery';
import ButtonStackFactory from '../ButtonStackFactory';
import * as commands from 'store/commands';
import getSelectedFor from '../getSelectedFor';
import { state } from 'store/ops';
import withQuery, { type withQueryProps } from '../withQuery';
import { type HOC } from 'recompose';

const Enhancer: HOC<withQueryProps<FiltersQuery>, *> = withQuery({
  query: FiltersQueryDef,
  itemsPath: 'data.company.tags',
  title: <h1 style={{ margin: '25px 0 20px' }}>Explore</h1>,
  selected: () => getSelectedFor('tags'),
  onSelect: value => commands.setTags(value),
  getFilters: () => state('filters'),
});

const Component = (props: withQueryProps<FiltersQuery>) =>
  ButtonStackFactory(props);

export default Enhancer(Component);
