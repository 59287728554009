/*@flow*/
import React from 'react';
import useTitle from 'lib/useTitle';
import { Link } from 'react-router-dom';
import Navbar from 'comps/App/Navbar';
import styled from '@emotion/styled';
import licImg1 from './pngs/brownsvillenow_img1.png';
import licImg2 from './pngs/brownsvillenow_img2.png';
import licImg3 from './pngs/brownsvillenow_img3.png';
import PresentedBy from 'comps/App/PresentedBy';
import useRouter from 'lib/useRouter';

const Page = styled.div({
  width: '100vw',
  overflow: 'hidden',
});

const Container = styled.div(`
  padding: 30px 40px;
`);

const Jobs = () => {
  useTitle('BrownsvilleNow - Jobs');
  const { history } = useRouter();
  const goBack = () => {
    history.goBack();
  };


  return (
    <Page>
      <Navbar
        textColor="var(--blue)"
        backBtn={
          <button onClick={goBack} css={{ padding: '5px 10px' }}>
            <i className="fa fa-angle-left" />
          </button>
        }
      >
        <Link style={{ marginTop: 2, display: 'inline-block' }} to="/">
        BrownsvilleNow
        </Link>
      </Navbar>
      <img style={{ width: '100%' }} src={licImg1} alt="brownsville" />
      <Container>
        <h1>Jobs in the Neighborhood</h1>
        <PresentedBy withColor />
        <p style={{ marginTop: 10 }}>
          <p>
          <h1>Search for jobs in and around the community!</h1>
          </p>
          <p>
          <h1><a href="https://www.pitkinavenue.nyc/jobs-in-the-neighborhood2">Click this link</a> to go to the Pitkin Avenue BID’s job’s page, where you can find opportunities
            within the Brownsville area.</h1>
          </p>
        </p>
      </Container>
      <img style={{ width: '100%' }} src={licImg2} alt="brownsville" />
      <Container>
        <h2>Business Participation:</h2> 
      <p>Is your business hiring?
      </p>
        <p>If so, email{' '}
          <a
            href="mailto:oliver@pitkinavenue.nyc"
            target="_blank"
            rel="noopener noreferrer"
          >
          bvillenow@pitkinavenue.nyc
          </a> to add your business job listing to our website. Please include the following information in your job listing:
          In the subject line add: "Business Job Listing: [company name]"
        </p>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img style={{ width: '100%' }} src={licImg3} alt="brownsville" />
        </div>
        <h3>NYC Small Business Services - Workforce 1</h3>
        <p>
            The NYC Department of Small Business Services prepares and helps New Yorkers to find jobs
            by offering free services in their network of Workforce1 Career Centers. They can help you
            prepare for your next interview, update your résumé, and connect with employers, or find the
            right training you need to succeed.
        </p>
        <br></br>
        <p>
          <h1>Find a Job on the SBS Career Page:</h1>
        </p>
          <p>
            <a href="https://www1.nyc.gov/site/sbs/careers/careers.page">Small Business Services - Careers Page</a>

          </p>
          <br></br>
        <h2>About us:</h2>
        <p>BrownsvilleNow is created and managed by the Pitkin Avenue Business Improvement District
          (BID), a non-profit Brownsville-based organization.
          The mission of the Brownsville Now program is to improve the accessibility and visibility of
          Brownsville businesses, community-based events, cultural attractions, local jobs as well as
          attract residents and visitors to Brownsville’s central commercial district.
        </p>
        <h2>Acknowledgements:</h2>
        <p>Brownsville Now is brought to you by the Pitkin Avenue BID.
          The site is developed by Ginkgo and funded by the NYC Department of Small Business
          Services (SBS) Neighborhood 360° (degree) Program.
          The BrownsvilleNow logo and smart signs are designed by Layman Lee, and fabricated by
          Brownsville’s CAB Signs located at 38 Livonia Avenue.
        </p>
        <p>
        The site is funded in part by NYC Department of Small Business Services Neighborhood 360 Program.
        </p>
        <p>
        The BrownsvilleNow logo and smart signs were designed by Layman Lee. The signs were fabricated by 
        Brownsville’s Cab Signs located at 38 Livonia Avenue. 
        </p>
      </Container>
    </Page>
  );
};

export default Jobs;
