/*@flow*/
export const SET_RECORD = 'SET_RECORD';

function orderLevels(lvls: string[]): { [key: string]: number } {
  const CAP = 100;
  return lvls.reduce(
    (s, v, i) => ({ ...s, [v]: (lvls.length - i) * CAP + 5000 }),
    {}
  );
}

export const LEVEL = orderLevels([
  'SIDEBAR',
  'NAVBAR',
  'FILTERS_PAGE',
  'SHOW_PAGE',
  'TOASTER',
  'SPINNER',
  'DRAWER',
  'MAP',
  'SCROLLER',
]);

export const CENTER_OF_LIC = [-73.90899872523734, 40.6682934884];

const mkValueObject = (value, unit) => {
  return new (class {
    value = value;
    unit = unit;
    toString = () => {
      return this.value + this.unit;
    };
  })();
};

export const DRAWER_HEIGHT = mkValueObject(265, 'px');
export const SEARCH_HEIGHT = mkValueObject(44, 'px');
export const RIDGE_HEIGHT = mkValueObject(56, 'px');

export const LIC_POLYGON = {
  type: 'Polygon',
  coordinates: [
    [
      [-73.8811,40.658254],
      [-73.882769,40.684916],
      [-73.941613,40.680459],
      [-73.939927,40.652707],
      [-73.8811,40.658254],
    ],
  ],
};
