/*@flow*/
import NonEmptyObject from 'lib/NonEmptyObject';
import { useQuery } from 'react-apollo-hooks';
import { useState } from 'react';
import type { DocumentNode } from 'graphql';

type Options = { variables?: Object };

export default function useCachedQuery(query: DocumentNode, opts?: Options) {
  const { data, ...rest } = useQuery(query, opts);
  const [newData, setData] = useState(data);
  if (data !== newData && NonEmptyObject.is(data)) {
    setData(data);
  }
  return { ...rest, data: newData };
}
