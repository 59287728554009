/*@flow*/
import AttractionsQueryDef from 'queries/Attractions';
import useCachedQuery from 'lib/useCachedQuery';
import useStoreState from 'lib/useStoreState';
import * as I from 'immutable';
import type { DocumentNode } from 'graphql';

type ItemT = { id: string } & { [key: string]: any };

function mixVenueToAttractions(
  vs: ItemT[],
  items: ItemT[]
): (ItemT & { isVenue: boolean })[] {
  const venueIds = vs.map(v => String(v.id));
  const isVenue = id => venueIds.includes(String(id));
  return items.map(v => ({ ...v, isVenue: isVenue(v.id) }));
}

export default function useData(
  queryDef: DocumentNode = AttractionsQueryDef,
  key: string = 'attractions'
) {
  const { filters, companyId } = useStoreState({
    filters: 'filters',
    companyId: 'companyId',
  });
  const variables = { filters, companyId };
  const o = { variables };
  const { data, loading: isLoading } = useCachedQuery(queryDef, o);
  const venues = I.getIn(data, ['company', 'venues'], []);
  let items = I.getIn(data, ['company', key], []);
  items = mixVenueToAttractions(venues, items);
  return [items, isLoading];
}
