/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { con, state } from 'store/ops';
import { type Map } from 'immutable';
import * as I from 'immutable';
import cx from 'classnames';
import categoryToColor from 'comps/Pages/Filters/categoryToColor';

const CanvasBackground = styled.div(
  () => `
  width: 100%;
  background: white;
  border: none;
  border-bottom: solid 1px #efefef;
  position: relative;
  bottom: -1px;
  box-shadow: 0px -8px 14px rgba(0,0,0,0.1);
  font-size: 0.725rem;
  line-height: 0.725rem;
`
);

const hasFilters = () =>
  state('filters', I.Map())
    .delete('bbox')
    .delete('limit').size > 0;

const Canvas = con({
  hasFilters,
})((props: { hasFilters: boolean, children: React$Node }) => (
  <CanvasBackground hasFilters={props.hasFilters}>
    {props.children}
  </CanvasBackground>
));

const DogEarBackground = styled.div(`
  width: 100%;
  background: white;
  text-align: center;
  padding: 5px 0;
  margin-bottom: 5px;
  border-top: solid 1px #efefef;
  color: #ccc;
`);

const DogEar = con({ isDown: 'Drawer.isDown' })(
  (props: { isDown: boolean }) => (
    <DogEarBackground className="dog-ear">
      Pull {props.isDown ? 'up' : 'down'}{' '}
      <i
        className={cx('fa', {
          'fa-caret-up': props.isDown,
          'fa-caret-down': !props.isDown,
        })}
      />
    </DogEarBackground>
  )
);

const FilterButton = props =>
  props.show ? (
    <button
      css={css(`
        height: 100%;
        marginRight: -10px;
      `)}
      onClick={props.onClick}
    >
      <i className="fa fa fa-sliders-h" />
    </button>
  ) : null;

const mkTagItems = props => {
  return ['Tenant', undefined].includes(props.filters.getIn(['type', 0]))
    ? props.filters
        .getIn(['businesses'], I.Map())
        .toList()
        .reduce((s, v) => s.concat(v), I.List())
    : props.filters.getIn(['events'], I.Map()).toList();
};

const TagGroup = styled.button(
  () => `
  outline: 0 !important;
  box-shadow: none;
  padding: 0;
  display: flex;
  justify-items: start;
  align-items: center;
  height: 100%;
  max-width: 90%;
`
);

const SectionName = styled.div(`
  height: 100%;
  margin-right: 10px;
  display: grid;
  justify-items: right;
  align-items: center;
`);

const Tag = styled.span(
  props => `
  padding: 4px;
  color: white;
  background: ${props.bgColor || 'var(--blue)'};
  border-radius: .25rem;
  margin-right: 3px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
);

const Tags = props => {
  if (props.filters.size === 0) return null;
  let items = mkTagItems(props);

  return (
    <TagGroup isDown={props.isDown} onClick={props.onClick}>
      {items.map((v, i) => (
        <Tag bgColor={categoryToColor(v)} key={i}>
          {v}
        </Tag>
      ))}
    </TagGroup>
  );
};

const InnerBackground = styled.div(
  props => `
  display: grid;
  align-items: center;
  height: 100%;
  grid-template-columns: calc(100% - ${props.isDown ? '30px' : '0px'}) 36px ${
    props.isDown ? '30px' : ''
  };
  margin-bottom: 5px;
  padding: 0 10px;
`
);

const Inner = con({
  isDown: 'Drawer.isDown',
  filters: 'filters',
  sectionName: 'Page.sectionName',
})(
  (props: {
    sectionName: ?string,
    onShowFilters: Function,
    isDown: boolean,
    filters: Map<*, *>,
  }) => {
    return (
      <InnerBackground isDown={props.isDown}>
        <div
          css={css(`
            display: flex;
            height: 100%;
          `)}
        >
          {props.sectionName && <SectionName>{props.sectionName}</SectionName>}
          <Tags
            isDown={props.isDown}
            filters={props.filters}
            onClick={props.onShowFilters}
          />
        </div>
        <FilterButton show={props.isDown} onClick={props.onShowFilters} />
      </InnerBackground>
    );
  }
);

export default (props: { onShowFilters: Function }) => {
  return (
    <Canvas className="ridge">
      <DogEar />
      <Inner onShowFilters={props.onShowFilters} />
    </Canvas>
  );
};
