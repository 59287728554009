/*@flow*/
import { compose, type HOC, lifecycle } from 'recompose';
import * as I from 'immutable';
import { con, set } from 'store/ops';
import adapter from './adapter';

type InputPropsT = {|
  namespace: string,
  storePath: string,
|};
type EnhancedPropsT = {| ...InputPropsT |};

// TODO: in addition we need the value of storePath...

//
//
// Usage:
//
//  <DataSync namespace='__lic_local__' storePath='Cache' />
//
const Enhancer: HOC<EnhancedPropsT, InputPropsT> = compose(
  con(props => [props.storePath]),
  lifecycle({
    componentDidMount() {
      // Load from localStorage to store
      const value = I.fromJS(adapter.readJSON(this.props.namespace) || {});
      set(this.props.storePath, value);
    },
    componentDidUpdate(prevProps: EnhancedPropsT) {
      // Update localStorage from store
      const { storePath: sp, namespace } = this.props;

      const storedValue = this.props[sp];
      const storedValueChanged = !I.is(prevProps[sp], storedValue);
      if (storedValueChanged) {
        const obj: { [key: string]: any } = storedValue.toJS();
        adapter.writeJSON(namespace, obj);
      }
    },
  })
);
const Component = () => null;
export default Enhancer(Component);
