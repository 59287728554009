/*@flow*/
// An event handler can take an optional event argument
// and should not return a value
type EventHandler = (event: any) => any;
type WildCardEventHandler = (type: string, event?: any) => any;

// An array of all currently registered event handlers for a type
// type EventHandlerList = EventHandler[];
// type WildCardEventHandlerList = WildCardEventHandler[];
// A map of event types and their corresponding event handlers.
export type EventHandlerMap = {
  '*'?: WildCardEventHandler,
  [type: string]: EventHandler,
};

export interface Mitt {
  on(type: string, handler: EventHandler | WildCardEventHandler): any;
  off(type: string, handler: EventHandler | WildCardEventHandler): any;
  emit(type: string, evt: any): any;
}

/** Mitt: Tiny (~200b) functional event emitter / pubsub.
 *  @name mitt
 *  @returns {Mitt}
 */
export default function mitt(evMap?: EventHandlerMap) {
  const all = evMap || Object.create(null);
  const arr = {};

  const on = (type: string, handler: EventHandler) => {
    (arr[type] || (arr[type] = [])).push(handler);
  };
  const off = (type: string, handler: EventHandler) => {
    if (arr[type]) {
      arr[type].splice(arr[type].indexOf(handler) >>> 0, 1); //eslint-disable-line
    }
  };
  const apply = fn => (o: Object | string, evt: any) => {
    if (typeof o === 'object') {
      Object.keys(o).forEach(k => typeof o !== 'string' && fn(k, o[k]));
    } else {
      fn(o, (evt: any));
    }
  };

  return {
    /**
     * Register an event handler for the given type.
     *
     * @param  {String|Object} type  Type of event to listen for, or `"*"` for all events
     * @param  {Function} handler Function to call in response to given event
     * @memberOf mitt
     */
    on: apply(on),

    /**
     * Remove an event handler for the given type.
     *
     * @param  {String|Object} type  Type of event to unregister `handler` from, or `"*"`
     * @param  {Function} handler Handler function to remove
     * @memberOf mitt
     */
    off: apply(off),

    /**
     * Invoke all handlers for the given type.
     * If present, `"*"` handlers are invoked after type-matched handlers.
     *
     * @param {String} type  The event type to invoke
     * @param {Any} [evt]  Any value (object is recommended and powerful), passed to each handler
     * @memberOf mitt
     */
    emit(type: string, evt: any) {
      const isF = v => typeof v === 'function';
      const isA = v => Array.isArray(v);
      const wrap = v =>
        ((isA(v) ? v : isF(v) ? [v] : []) || []).slice().filter(Boolean);

      wrap(all[type]).map(h => typeof h === 'function' && h(evt));
      wrap(all['*']).map((h: WildCardEventHandler) => h(type, evt));
    },
  };
}
