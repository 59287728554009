/*@flow*/
import gql from 'graphql-tag';

export default gql`
  query BizQuery($companyId: ID!, $filters: FiltersInput!) {
    company(id: $companyId) {
      bizPages(filters: $filters) {
        id
        name
        lonlat
        photo
        category
        type
        address
      }
      venues(filters: $filters) {
        id
      }
    }
  }
`;
