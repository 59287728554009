/*@flow*/
import styled from '@emotion/styled';

export default styled.div`
  .mapboxgl-user-location-dot {
    --radius: 30px;
    width: var(--radius);
    height: var(--radius);
    &::before {
      width: var(--radius);
      height: var(--radius);
    }
    &::after {
      width: calc(var(--radius) + 4px);
      height: calc(var(--radius) + 4px);
    }
    svg {
      width: calc(var(--radius) - 6px);
      height: calc(var(--radius) - 6px);
      color: white;
      position: relative;
      z-index: 99;
      left: 3px;
      top: 2px;
    }
  }
`;
