/*@flow*/
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import styled from '@emotion/styled';
import cx from 'classnames';
import * as commands from 'store/commands';
import { LEVEL } from 'store/constants';
import useRouter from 'lib/useRouter';

export const NAVBAR_HEIGHT = '57px';

const Container = styled.nav(
  props => `
  z-index: ${LEVEL.NAVBAR};
  color: ${props.textColor};
  & a, & button {
    color: ${props.textColor};
    pointer-events: auto;
  }
  .navbar-brand {
    margin-right: 0;
  }
  ${
    props.location.pathname !== '/'
      ? `
    box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.2);
    padding-bottom: 5px;
    background: white;
  `
      : ''
  }
`
);

type InputPropsT = {|
  children?: React$Node,
  fixed?: boolean,
  sticky?: boolean,
  textColor?: string,
  backBtn?: React$Node,
|};

const Component = ({
  children,
  fixed,
  sticky,
  textColor = 'white',
  backBtn,
}: InputPropsT) => {
  const { location } = useRouter();
  return (
    <React.Fragment>
      <Container
        className={cx({ 'fixed-top': fixed, 'sticky-top': sticky })}
        textColor={textColor}
        location={location}
      >
        <div
          css={css(`
          position: relative;
          top: -1px;
          padding: .5rem 0;
          height: ${NAVBAR_HEIGHT};
      `)}
        >
          <div className="text-center" style={{ position: 'sticky' }}>
            {children && <span className="navbar-brand">{children}</span>}
          </div>

          {backBtn ? (
            <span style={{ position: 'absolute', left: 10, top: 17 }}>
              {backBtn}
            </span>
          ) : null}
          <button
            className="btn btn-link"
            style={{ position: 'absolute', right: 10, top: 10 }}
            onClick={() => {
              commands.toggleSidebar();
            }}
          >
            <span className="fa fa-bars" />
          </button>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Component;
