/*@flow*/
import type { ActionT, ContextT } from 'store/types';
type ActionFnT<T> = ({ ...T, ...ContextT }) => any;
export type ThunkedActionT<T> = ActionT | ActionFnT<T>;
type Next = ActionT => ActionT;
type NFT = any => ActionT;

const id = (_: any) => _;

export function createThunkMiddleware<T: Object>(extra: T) {
  return (ctx: ContextT) => {
    return (next: Next, nextFromThunk?: NFT = id) => {
      const fn = (action: ThunkedActionT<T>): ActionT => {
        if (typeof action !== 'function') return next(action);
        const result = action({ ...extra, ...ctx });
        if (typeof result !== 'function' && result) return fn(result);
        return nextFromThunk(result);
      };
      return fn;
    };
  };
}

export default createThunkMiddleware({});
