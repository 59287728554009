/*@flow*/
import { con } from 'store/ops';
import { compose, type HOC, withStateHandlers, withHandlers } from 'recompose';
import { type ButtonStackPropsT } from 'comps/App/ButtonStack';

const mkFn = value => (typeof value === 'function' ? value : () => value || []);

export default (props: {
  onSelect: (string[], string) => void,
  selected: string[] | (() => string[]),
  items?: string[] | (() => string[]),
  itemLimit?: number | void,
}): HOC<ButtonStackPropsT<>, *> => {
  return compose(
    con({
      selected: mkFn(props.selected),
      items: mkFn(props.items),
    }),
    withStateHandlers(
      () => {
        let extra = {};
        if (Array.isArray(props.selected)) {
          extra = {
            selected: props.selected,
          };
        }
        return {
          showMore: true,
          itemLimit: props.itemLimit,
          ...extra,
        };
      },
      {
        update: () => s => s,
        onMore: ({ showMore }) => () => {
          return {
            showMore: !showMore,
            itemLimit: showMore ? 4 : 999,
          };
        },
      }
    ),
    withHandlers({
      onSelect: ({ selected: s, update }) => item => {
        s = s.includes(item) ? s.filter(v => v !== item) : s.concat([item]);
        props.onSelect && props.onSelect(s, item);
        if (Array.isArray(props.selected)) {
          update({ selected: s });
        }
      },
    })
  );
};
