/*@flow*/
import type { Map, Layer } from 'mapbox-gl';
import type { EventMap } from '../';

type DeckT = {
  id: string,
  type: 'layer',
  config: Layer,
  placeBefore?: string,
  events: EventMap,
};

export default (deck: DeckT, map: Map) => {
  const getLayer = () => map.getLayer(deck.config.id);
  const hasLayer = () => getLayer() !== undefined;
  const bind = o => map.on(o[0], deck.config.id, o[1]);
  const unbind = o => map.off(o[0], deck.config.id, o[1]);

  const enable = () => {
    if (hasLayer()) return;
    const { config } = deck;
    const { source } = config;
    const hasStringSource = typeof source === 'string' && map.getSource(source);
    const addLayer = () => {
      if (hasLayer()) return;
      const layers = map.getStyle().layers || [];

      if (
        deck.placeBefore &&
        layers.map(v => v.id).includes(deck.placeBefore)
      ) {
        map.addLayer(config, deck.placeBefore);
      } else {
        map.addLayer(config);
      }
    };

    if (!source) {
      addLayer();
    } else if (hasStringSource || typeof source === 'object') {
      addLayer();
    } else {
      setTimeout(enable, 100);
      return;
    }

    const ev = deck.events || {};
    Object.keys(ev).forEach(k => bind([k, ev[k]]));
  };

  return {
    enable,
    update(newDeck: DeckT) {
      deck = newDeck;
    },
    disable() {
      if (hasLayer()) {
        const ev = deck.events || {};
        Object.keys(ev).forEach(k => unbind([k, ev[k]]));

        map.removeLayer(deck.config.id);
      }
    },
  };
};
