/*@flow*/
import React, { useState } from 'react';
import truncate from 'underscore.string/truncate';

const CHAR_LIMIT = 100;
type PropsT = {
  text: ?string,
  limit?: number,
  html?: boolean,
  className?: string,
};

const Content = ({ value, html }) => {
  if (!html) return value;
  return <span dangerouslySetInnerHTML={{ __html: value }} />;
};

const ToggleBtn = props => (
  <button
    onClick={() => setTimeout(() => props.onClick(v => !v), 100)}
    className="btn btn-link"
  >
    {props.isOpen ? 'Show Less...' : 'Show More...'}
  </button>
);

const Component = (props: PropsT) => {
  const [isOpen, setOpen] = useState(false);
  if (!props.text) return null;
  const limit = props.limit || CHAR_LIMIT;
  const hasOverflow = props.text.length > limit;
  let content = props.text;

  if (!isOpen && hasOverflow) {
    content = truncate(props.text, limit);
  }

  return (
    <div className={props.className || ''}>
      <Content value={content} html={props.html} />
      {hasOverflow && <ToggleBtn onClick={setOpen} isOpen={isOpen} />}
    </div>
  );
};

export default Component;
