/*@flow*/
import React from 'react';
import useTitle from 'lib/useTitle';
import { Link } from 'react-router-dom';
import Navbar from 'comps/App/Navbar';
import styled from '@emotion/styled';
import useRouter from 'lib/useRouter';

const Page = styled.div({
  width: '100vw',
  overflow: 'hidden',
});

const Feedback = () => {
  useTitle('BrownsvilleNow - Feedback');
  const { history } = useRouter();
  const goBack = () => {
    history.goBack();
  };


  return (
    <Page>
      <Navbar
        textColor="var(--blue)"
        backBtn={
          <button onClick={goBack} css={{ padding: '5px 10px' }}>
            <i className="fa fa-angle-left" />
          </button>
        }
      >
        <Link style={{ marginTop: 2, display: 'inline-block' }} to="/">
        BrownsvilleNow
        </Link>
      </Navbar>
      <div></div>
        <React.Fragment>
          <div />
          <div></div>
            <iframe title="feedbackForm" src="https://www.cognitoforms.com/f/SOIfznafU0Sd6DjaKt2Lpw/11" style={{width: '100%'}} height="1000"></iframe>
            <script src="https://www.cognitoforms.com/f/iframe.js"></script>
          <div />
        </React.Fragment>
        <div></div>
    </Page>
  );
};

export default Feedback;