/*@flow*/
import mockStorage from 'lib/localStorage';

let backend = (window._localStorage = mockStorage());

try {
  backend = window.localStorage;
} catch (_) {} //eslint-disable-line

type Record = { [key: string]: any };

const adapter = {
  readString: (key: string) => backend.getItem(key),
  writeString: (key: string, data: string) => {
    backend.setItem(key, data);
  },
  readJSON: (key: string): Record | Array<*> | null => {
    const val = adapter.readString(key);
    if (!val) return null;
    return JSON.parse(val);
  },
  writeJSON: (key: string, data: Record | Array<*>) => {
    adapter.writeString(key, JSON.stringify(data));
  },
};

export default adapter;
