// @flow
import DOM from '../../util/dom';
import { Marker } from 'mapbox-gl';
import type { Map } from 'mapbox-gl';
import React from 'react';
import positionWatcher from 'lib/positionWatcher';
import createSVGIcon from 'lib/createSVGIcon';
import mitt, { type EventHandlerMap, Mitt } from 'lib/mitt';

type Props = { map: Map, symbol?: string, events?: EventHandlerMap };

class UserLocation extends React.Component<Props> {
  _dotElement: HTMLElement;
  _userLocationDotMarker: ?Marker;
  emitter: Mitt;

  componentDidMount() {
    this.emitter = mitt(this.props.events || {});

    this._setupUI();

    positionWatcher().track(
      position => {
        this._updateMarker(position);
        this._dotElement.classList.remove('mapboxgl-user-location-dot-stale');
        this.emitter.emit('ready');
      },
      _ => {
        this._dotElement.classList.add('mapboxgl-user-location-dot-stale');
      }
    );
  }

  componentWillUnmount() {
    this.removeMarker();
  }

  removeMarker() {
    this._userLocationDotMarker && this._userLocationDotMarker.remove();
  }

  _updateMarker(position: ?Position) {
    if (position && this._userLocationDotMarker) {
      this._userLocationDotMarker
        .setLngLat([position.coords.longitude, position.coords.latitude])
        .addTo(this.props.map);
    } else {
      this.removeMarker();
    }
  }

  _dotSvg: SVGElement;

  _setupUI() {
    this._dotElement = DOM.create('div', 'mapboxgl-user-location-dot');
    if (this.props.symbol) {
      this._dotSvg = createSVGIcon(this.props.symbol);
      this._dotElement.appendChild(this._dotSvg);
    }

    this._userLocationDotMarker = new Marker(this._dotElement);
  }

  render() {
    return null;
  }
}

export default UserLocation;
