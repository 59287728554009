/*@flow*/
import type { Map } from 'mapbox-gl';
import UserLocation from '../ui/UserLocation';
import React from 'react';
import { type EventHandlerMap } from 'lib/mitt';

type DeckT = {|
  id: string,
  type: 'userLocationMarker',
  symbol?: string,
  events?: EventHandlerMap,
|};

export default (deck: DeckT, map: Map) => {
  return {
    enable() {
      return <UserLocation {...deck} map={map} />;
    },
    update(_newDeck: DeckT) {
      return <UserLocation {...deck} map={map} />;
    },
    disable() {},
  };
};
