// @flow

let supportsGeolocation;

export default (): Promise<void> => {
  return new Promise((res, rej) => {
    if (supportsGeolocation !== undefined) {
      if (supportsGeolocation) {
        res();
      } else {
        rej('Does not support geolocation.');
      }
    } else if (window.navigator.permissions !== undefined) {
      // navigator.permissions has incomplete browser support
      // http://caniuse.com/#feat=permissions-api
      // Test for the case where a browser disables Geolocation because of an
      // insecure origin
      window.navigator.permissions.query({ name: 'geolocation' }).then(p => {
        supportsGeolocation = p.state !== 'denied';
        if (supportsGeolocation) {
          res();
        } else {
          rej('Geolocation permission denied.');
        }
      });
    } else {
      supportsGeolocation = !!window.navigator.geolocation;
      if (supportsGeolocation) {
        res();
      } else {
        rej('No geolocation in navigator.');
      }
    }
  });
};
