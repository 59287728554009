/*@flow*/
import React from 'react';
import useTitle from 'lib/useTitle';
import styled from '@emotion/styled';
import Mobile from './Mobile';
import Desktop from './Desktop';
import mobilecheck from 'lib/mobilecheck';
import useRouter from 'lib/useRouter';
import * as I from 'immutable';

const HomeBg = styled.div`
  background: var(--primary-gradient);
  height: inherit;
  min-height: 800px;
`;

const Home = () => {
  useTitle('BrownsvilleNow - Explore your neighborhood');
  const { location } = useRouter();

  const noDesktopQuery = !I.getIn(location, ['query', 'desktop'], false);
  const hasMobileQuery = I.getIn(location, ['query', 'mobile'], false);

  const isLocal = window.location.host === 'localhost:3000';
  const alternativeLogic = isLocal ? noDesktopQuery : hasMobileQuery;

  const isMobile = mobilecheck() || alternativeLogic;

  return <HomeBg>{isMobile ? <Mobile /> : <Desktop />}</HomeBg>;
};

export default Home;
