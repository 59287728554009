/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export default (props: { children: React$Node }) => {
  return (
    <div
      css={css(`
        display: grid;
        grid-template-columns: 65px 1fr;
        grid-column-gap: 15px;
        align-items: center;
        & > div:nth-of-type(1) {
          text-align: center;
          color: #333;
        }
    `)}
    >
      {props.children}
    </div>
  );
};
