/*@flow*/
import { useEffect, useState } from 'react';
import useRouter from 'lib/useRouter';

const pageTitle = () => {
  const el = document.querySelector('title');
  if (el) return el.innerText;
};

const mkTitle = (np, input) => {
  let title;
  if (typeof input === 'function') {
    title = input(np);
  } else {
    title = input;
  }
  const prefix = process.env.__DEV__ ? '[DEV] ' : '';
  return prefix + title;
};

export default (input: Function | string) => {
  const [title, setLocalTitle] = useState('');
  const [savedUrl, setUrl] = useState('');
  const ctx = useRouter();

  const setTitle = np => {
    const el = document.querySelector('title');
    if (!el) return;
    const fullTitle = mkTitle(np, input);
    el.innerText = fullTitle;
    setLocalTitle(fullTitle);
  };

  useEffect(() => {
    setTitle(ctx);
  }, []);

  const { pathname } = ctx.location;

  useEffect(() => {
    setUrl(ctx.match.url);

    const newTitle = mkTitle(ctx, input);
    const titleChanced = newTitle !== title;
    const pageTitleChanged = pageTitle() !== newTitle;
    const isSamePath = savedUrl === ctx.location.pathname;

    if (pageTitleChanged && titleChanced && isSamePath) {
      setTitle(ctx);
    }
  }, [pathname]);
};
