/*@flow*/

const nested = (path: string, defaultValue: any) => (o: Object): any => {
  const fn = (s, k) => (s && typeof s === 'object' ? s[k] : '');
  return path.split('.').reduce(fn, o) || defaultValue;
};

export const nestedFlip = (o: Object) => (path: string, dv: any) =>
  nested(path, dv)(o);

export default nested;
