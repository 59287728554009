/*@flow*/
import * as I from 'immutable';
const BASE = 'https://cdn.filestackcontent.com';

// @param {String} url The Filestack url to be processed. It includes a Filestack id
// at the end. Example: https://filestackcontent.com/:id
//
// @param {number} width
// @param {number} height
//
// @returns {String} Processed url.
export default (url: string, width: number, height: number) => {
  const id = I.List(url.split('/')).last();
  return `${BASE}/resize=w:${width},h:${height},f:crop,a:center/${id}`;
};
