// @flow
import supportsGeolocation from 'lib/supportsGeolocation';
import mobilecheck from 'lib/mobilecheck';
import { CENTER_OF_LIC } from 'store/constants';

let geolocationWatchID;
let lastKnownPosition;
let listeners = [];
let errorListeners = [];

const mkFakePos = (lon, lat) => {
  class Coordinates {
    latitude: number;
    longitude: number;
    accuracy: number;
  }
  const coords = new Coordinates();
  coords.latitude = lat;
  coords.longitude = lon;
  coords.accuracy = 1;

  class Position {
    coords: Coordinates;
    timestamp: number;
  }
  const pos = new Position();
  pos.coords = coords;
  pos.timestamp = Date.now();
  return pos;
};

const inDebugMode = () => false;

const geo = window.navigator.geolocation;

const clearWatch = () => {
  if (!geolocationWatchID) return;
  geo.clearWatch(geolocationWatchID);
  geolocationWatchID = undefined;
  lastKnownPosition = undefined;
  listeners = [];
  errorListeners = [];
};

const startWatch = (options: PositionOptions) => {
  const onSucc = (position: Position) => {
    if (inDebugMode()) {
      position = (mkFakePos(...CENTER_OF_LIC): any);
      console.warn('FAKE POSITION: ', position);
    }
    // keep a record of the position
    lastKnownPosition = position;
    listeners.forEach(fn => fn(position));
  };

  // see: https://developer.mozilla.org/en-US/docs/Web/API/PositionError
  const onFail = (error: PositionError) => {
    // PERMISSION_DENIED
    if (error.code === 1 && geolocationWatchID !== undefined) {
      clearWatch();
    }
    errorListeners.forEach(fn => fn(error));
  };

  supportsGeolocation()
    .then(() => {
      geolocationWatchID = geo.watchPosition(onSucc, onFail, options);
    })
    .catch(onFail);
};

const enableHighAccuracy =
  mobilecheck() && window.location.host !== 'localhost:3000';

const positionOptions = {
  enableHighAccuracy,
  maximumAge: 0,
  timeout: 6000 /* 6 sec */,
};

// Usage:
//
//    positionWatcher().track(position => {
//      set('Geo.position', position)
//      set('Geo.tracking', true)
//    }, err => {
//      window.onerror(err)
//      set('Geo.tracking', false)
//    })
//
export default (options?: PositionOptions = positionOptions) => {
  options = { ...positionOptions, ...options };
  const getPosition = (): Promise<Position> => {
    return new Promise((res, rej) => {
      if (geolocationWatchID === undefined) {
        listeners.push(position => res(position));
        errorListeners.push(err => rej(err));
        startWatch(options);
      } else if (lastKnownPosition) {
        res(lastKnownPosition);
      } else {
        clearWatch();
        return getPosition();
      }
    });
  };
  return {
    getPosition,
    track: (
      listener: Position => void,
      errorListener: PositionError => void
    ) => {
      // Add listeners only once
      listeners = listeners.filter(v => v !== listener);
      listeners.push(listener);
      errorListeners = errorListeners.filter(v => v !== errorListener);
      errorListeners.push(errorListener);
      if (geolocationWatchID === undefined) {
        startWatch(options);
      }
      if (lastKnownPosition) {
        listener(lastKnownPosition);
      }
    },
    finish: clearWatch,
  };
};
