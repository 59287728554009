/*@flow*/
import useTitle from 'lib/useTitle';
import PagesIndex from 'comps/Pages/Index';
import * as commands from 'store/commands';
import React, { useEffect } from 'react';
import MapContext from '../Index/MapContext';
import useData from 'lib/useData';
import eventQueryDef from 'queries/Events';

export default () => {
  useTitle('BrownsvilleNow - Events');
  useEffect(() => {
    commands.setSectionName('Events');
    commands.setEventPageFiltersOnLoad();
  }, []);

  const [items, isLoading] = useData(eventQueryDef, 'eventPages');

  return (
    <MapContext.Provider value={{ items, isLoading, decks: [] }}>
      <PagesIndex />
    </MapContext.Provider>
  );
};
