/*@flow*/
import React from 'react';
import styled from '@emotion/styled';

const Background = styled.div(
  props => `
  text-align: center;
  background: white;
  display: grid;
  height: ${props.height || '150px'};
  width: 100%;
  justify-items: center;
  align-items: center;
`,
  ({ style }) => style || {}
);
export default (props: { show?: boolean, style?: Object, height?: string }) =>
  props.show ? (
    <Background {...props}>
      <i className="fa fa-spinner fa-spin" />
    </Background>
  ) : null;
