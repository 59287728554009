/*@flow*/
import React from 'react';
import { Link } from 'react-router-dom';
import * as commands from 'store/commands';
import styled from '@emotion/styled';
import { state } from 'store/ops';
import RightArrow from 'comps/App/RightArrow';

const Apply = styled.div(`
  a {
    background: var(--yellow);
    color: white;
    width: 100%;
    height: 60px;
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
    font-size: 1.25rem;
  }
`);

const Component = (props: { destination: string }) => {
  const query = { filters: state('filters').toJS() };
  return (
    <Apply>
      <Link
        to={{ pathname: props.destination, query }}
        onClick={() => {
          commands.deleteClickedItem();
          commands.hideDetails();
        }}
      >
        <span>
          See results <RightArrow />
        </span>
      </Link>
    </Apply>
  );
};

export default Component;
