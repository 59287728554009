/*@flow*/

export const CategoryColorMap = {
  'Food & Drink': '#ED645A',
  'Arts & Culture': '#7f3092',
  'Health & Beauty': '#1B998B',
  'Shopping': '#5D69B1',
  'Neighborhood Amenities': '#2674ae', 
  'Community & Faith Based Orgs': '#58ae26', 
  'Pitkin Advantage': '#3B61B6',
};

export default (value: $Keys<typeof CategoryColorMap>) => {
  return CategoryColorMap[value];
};
