/*@flow*/
import React from 'react';
import styled from '@emotion/styled';
import type { Map } from 'mapbox-gl';
import { compose, lifecycle, type HOC } from 'recompose';
import waitTill from 'lib/waitTill';
import { LEVEL } from 'store/constants';

const Center = styled.div(`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: ${LEVEL.SPINNER};
  display: grid;
  justify-items: center;
  align-items: center;
  pointer-events: none;
`);

const CircleLoader = styled.div(
  `
  display: block;
  font-size: 0;
  color: #333;
  width: 32px;
  height: 32px;

  & > div {
      position: relative;
      box-sizing: border-box;
      display: inline-block;
      float: none;
      background-color: currentColor;
      border: 0 solid currentColor;
      width: 32px;
      height: 32px;
      background: transparent;
      border-width: 2px;
      border-bottom-color: transparent;
      border-radius: 100%;
      animation: ball-clip-rotate .75s linear infinite;
  }

  @keyframes ball-clip-rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
);

let waiter;
type InputPropsT = {| map: Map, loading: boolean |};
type EnhancedPropsT = InputPropsT;

const Enhancer: HOC<EnhancedPropsT, InputPropsT> = compose(
  lifecycle({
    componentDidMount() {
      this._onData = this.onData.bind(this);
      this.props.map.on('data', this._onData);
      this._isMounted = true;
      setTimeout(() => this._isMounted && this.forceUpdate(), 3000);
    },
    onData(e) {
      if (e.dataType === 'source') {
        if (!e.target.areTilesLoaded()) {
          if (!waiter) {
            waiter = waitTill(() => e.target.areTilesLoaded()).then(() => {
              this._isMounted && this.forceUpdate();
              waiter = undefined;
            });
          }
        }
      }
    },
    componentWillUnmount() {
      this.props.map.off('data', this._onData);
      this._isMounted = false;
    },
  })
);

const Component = (props: EnhancedPropsT) =>
  props.map.areTilesLoaded() && !props.loading ? null : (
    <Center>
      <CircleLoader>
        <div />
      </CircleLoader>
    </Center>
  );

export default Enhancer(Component);
