/*@flow*/
/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx } from '@emotion/core';
import crossImage from 'images/cross.png';
import { con, truthy } from 'store/ops';
import { type HOC, compose } from 'recompose';
import { LEVEL } from 'store/constants';
import * as commands from 'store/commands';

const Container = styled.div(
  props => `
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: ${props.bgColor || 'rgba(153, 204, 51, 0.9)'};
  z-index: ${LEVEL.SIDEBAR};

  opacity: ${props.isOpen ? 1 : 0};
  visibility: ${props.isOpen ? 'visible' : 'hidden'};
  transition: ${
    props.isOpen ? 'opacity 0.5s' : 'opacity 0.5s, visibility 0s 0.5s'
  };
`
);

const Nav = styled.nav(
  props => `
  position: relative;
  top: calc(${props.offsetTop} * 1.5);
  transform: translateY(calc(-2 * ${props.offsetTop}));
  perspective: 1200px;
`
);

const Header = styled.header(
  `
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
`
);

const Transition = styled.nav(
  props => `
  opacity: ${props.isOpen ? 1 : 0.4};
  transform: ${props.transform};
  transition: transform 0.5s, opacity 0.5s;
`
);

const CloseButton = styled.button(`
  width: 40px;
  height: 40px;

  position: absolute;
  left: 10px;
  top: 10px;
  overflow: hidden;
  border: none;
  // background: url(${crossImage}) no-repeat center center;
  background-size: 20px 20px;

  text-indent: 200%;
  color: transparent;
  outline: none;
`);

type Status = 'OPEN' | 'CLOSED' | 'CLOSING';
type InputPropsT = {
  bgColor?: string,
  header?: React$Node,
  offsetTop?: string,
  children: React$Node,
};
type EnhancedPropsT = {
  ...InputPropsT,
  offsetTop: string,
  status: Status,
  isOpen: boolean,
};

const OFFSET_TOP = '25%';

const getNavTransform = (props: EnhancedPropsT) => {
  const m = {
    OPEN: 'rotateX(0deg)',
    CLOSING: `translateY(${props.offsetTop}) rotateX(-35deg)`,
    CLOSED: `translateY(-${props.offsetTop}) rotateX(35deg)`,
  };
  return m[props.status];
};

const Enhance: HOC<EnhancedPropsT, InputPropsT> = compose(
  con({
    isOpen: truthy('ui.Sidebar.isOpen'),
    status: 'ui.Sidebar.status',
    offsetTop: props => props.offsetTop || OFFSET_TOP,
  })
);

const Component = (props: EnhancedPropsT) => {
  let navTransform = getNavTransform(props);

  return (
    <Container bgColor={props.bgColor} isOpen={props.isOpen}>
      {props.header && <Header>{props.header}</Header>}
      <CloseButton onClick={() => commands.closeSidebar()}>Close</CloseButton>
      <Nav offsetTop={props.offsetTop}>
        <Transition transform={navTransform} isOpen={props.isOpen}>
          {props.children}
        </Transition>
      </Nav>
    </Container>
  );
};

export default Enhance(Component);
