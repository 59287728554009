/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import BookmarkButton from './BookmarkButton';

type PropsT = { destination: ?string, id: string };

export default (props: PropsT) => {
  if (!props.destination) return null;
  // Line below is to make Flow happy.
  const destination = props.destination || '';
  return (
    <div
      css={css`
        padding: 10px 0;
        position: relative;
      `}
    >
      <hr
        css={css(`
          border-color: var(--blue);
      `)}
      />
      <span
        css={css(`
        position: absolute;
        left: 10px;
        top: 8px;
        i {
          background: white;
        }
      `)}
      >
        <BookmarkButton id={props.id} />
      </span>
      <a
        href={`https://www.google.com/maps/dir/?api=1&destination=${destination}&travelmode=walking`}
        target="_blank"
        rel="noopener noreferrer"
        css={css(`
          text-transform: uppercase;
          color: white;
          background: var(--primary-gradient);
          border-radius: 20px;
          padding: 5px 15px;
          position: absolute;
          right: 0;
          top: 8px;
          border: solid 2px white;
          font-size: 0.875rem;
          &:focus {
            outline: 0 !important;
          }
        `)}
      >
        Directions <i className="fa fa-caret-right" css={{ marginLeft: 5 }} />
      </a>
    </div>
  );
};
