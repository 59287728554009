/*@flow*/
import React from 'react';
import Hammer from 'hammerjs';
import hasProperty from 'lib/hasProperty';

let privateProps = {
  children: true,
  direction: true,
  options: true,
  recognizeWith: true,
  vertical: true,
};

/**
 * Hammer Component
 * ================
 */

const handlerToEvent = {
  action: 'tap press',
  onDoubleTap: 'doubletap',
  onPan: 'pan',
  onPanCancel: 'pancancel',
  onPanEnd: 'panend',
  onPanStart: 'panstart',
  onPanMove: 'panmove',
  onPanUp: 'panup',
  onPanDown: 'pandown',
  onPinch: 'pinch',
  onPinchCancel: 'pinchcancel',
  onPinchEnd: 'pinchend',
  onPinchIn: 'pinchin',
  onPinchOut: 'pinchout',
  onPinchStart: 'pinchstart',
  onPress: 'press',
  onPressUp: 'pressup',
  onRotate: 'rotate',
  onRotateCancel: 'rotatecancel',
  onRotateEnd: 'rotateend',
  onRotateMove: 'rotatemove',
  onRotateStart: 'rotatestart',
  onSwipe: 'swipe',
  onSwipeRight: 'swiperight',
  onSwipeLeft: 'swipeleft',
  onSwipeUp: 'swipeup',
  onSwipeDown: 'swipedown',
  onTap: 'tap',
  onClick: 'click',
};

type Directions =
  | 'DIRECTION_NONE'
  | 'DIRECTION_LEFT'
  | 'DIRECTION_RIGHT'
  | 'DIRECTION_UP'
  | 'DIRECTION_DOWN'
  | 'DIRECTION_HORIZONTAL'
  | 'DIRECTION_VERTICAL'
  | 'DIRECTION_ALL';

type Recognizer =
  | Hammer.Pan
  | Hammer.Pinch
  | Hammer.Press
  | Hammer.Rotate
  | Hammer.Swipe
  | Hammer.Tap;

// see: http://hammerjs.github.io/jsdoc/Hammer.defaults.cssProps.html
type CssProps = {
  // Specifies whether zooming is enabled. Used by IE10>
  contentZoomingString: 'none',
  // Overrides the highlight color shown when the user taps a link or a JavaScript
  // clickable element in iOS. This property obeys the alpha value, if specified.
  tapHighlightColorString: 'rgba(0,0,0,0)',
  // Disables the default callout shown when you touch and hold a touch target.
  // On iOS, when you touch and hold a touch target such as a link, Safari displays
  // a callout containing information about the link. This property allows you to disable that callout.
  touchCalloutString: 'none',
  // Disable the Windows Phone grippers when pressing an element.
  touchSelectString: 'none',
  // Specifies that an entire element should be draggable instead of its contents. Mainly for desktop browsers.
  userDragString: 'none',
  // Disables text selection to improve the dragging gesture. Mainly for desktop browsers.
  userSelectString: 'none',
};

type Preset = 'tap' | 'doubletap' | 'press' | 'horizontal' | 'pan' | 'swipe';

// see: http://hammerjs.github.io/touch-action/
type TouchAction = 'compute' | 'auto' | 'pan-y' | 'pan-x' | 'none';

type Options = $Shape<{
  recognizers: { [gesture: string]: Recognizer },
  touchAction: TouchAction,
  domEvents: boolean,
  enable: boolean,
  cssProps: CssProps,
  preset: Preset[],
}>;

type Handlers = $ObjMap<typeof handlerToEvent, () => Function>;

// --------------------------------

Object.keys(handlerToEvent).forEach(key => {
  privateProps = { ...privateProps, [key]: true };
});

function updateHammer(hammer, props) {
  if (hasProperty(props, 'vertical')) {
    console.warn('vertical is deprecated, please use `direction` instead');
  }

  const directionProp = props.direction;
  if (directionProp || hasProperty(props, 'vertical')) {
    const direction =
      directionProp ||
      (props.vertical ? 'DIRECTION_ALL' : 'DIRECTION_HORIZONTAL');
    hammer.get('pan').set({ direction: Hammer[direction] });
    hammer.get('swipe').set({ direction: Hammer[direction] });
  }

  if (props.options) {
    Object.keys(props.options).forEach(option => {
      if (option === 'recognizers') {
        Object.keys(props.options.recognizers).forEach(gesture => {
          const recognizer = hammer.get(gesture);
          recognizer.set(props.options.recognizers[gesture]);
          if (props.options.recognizers[gesture].requireFailure) {
            recognizer.requireFailure(
              props.options.recognizers[gesture].requireFailure
            );
          }
        }, this);
      } else {
        const key = option;
        const optionObj = {};
        optionObj[key] = props.options[option];
        hammer.set(optionObj);
      }
    }, this);
  }

  if (props.recognizeWith) {
    Object.keys(props.recognizeWith).forEach(gesture => {
      const recognizer = hammer.get(gesture);
      recognizer.recognizeWith(props.recognizeWith[gesture]);
    }, this);
  }

  Object.keys(props)
    .filter(p => p in handlerToEvent)
    .forEach(p => {
      // $FlowFixMe
      const e = handlerToEvent[p];
      hammer.off(e);
      hammer.on(e, props[p]);
    });
}

// ------------------------------------

type PropsT = $Shape<{
  ...Handlers,
  children: React$Node,
  direction: Directions,
  options: Options,
  recognizeWith: Recognizer,
  vertical: boolean,
  className: string,
}>;

export default class HammerComponent extends React.Component<PropsT, *> {
  static displayName = 'Hammer';

  hammer: Hammer;
  ref: React$Ref<*>;

  componentDidMount() {
    this.hammer = new Hammer(this.ref);
    updateHammer(this.hammer, this.props);
  }

  componentDidUpdate() {
    if (this.hammer) {
      updateHammer(this.hammer, this.props);
    }
  }

  componentWillUnmount() {
    if (this.hammer) {
      this.hammer.stop();
      this.hammer.destroy();
    }
    this.hammer = null;
  }

  render() {
    let props = {};

    Object.keys(this.props).forEach(function(key) {
      if (!(key in privateProps)) {
        props[key] = this.props[key];
      }
    }, this);

    // Reuse the child provided
    // This makes it flexible to use whatever element is wanted (div, ul, etc)
    return React.cloneElement(React.Children.only(this.props.children), {
      ...props,
      ref: el => {
        if (el) {
          this.ref = el;
        }
      },
    });
  }
}
