/*@flow*/
import styled from '@emotion/styled';
import { DRAWER_HEIGHT } from 'store/constants';

export default styled.div(`
  --c-w: 80vw;
  --c-h: ${DRAWER_HEIGHT.toString()};

  width: var(--c-w);
  height: var(--c-h);

  background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8) 50%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(rgba(0, 0, 0, 0.1) ${DRAWER_HEIGHT.toString()}, transparent 0);

  background-repeat: no-repeat;

  --highlight-dim: 50px 200px;
  --image-dim: 100% 200px;
  background-size: 50px 200px, var(--image-dim);

  --highlight-pos: -100% 0;
  --image-pos: 0px 0px;
  background-position: var(--highlight-pos), var(--image-pos);

  animation: shine 1.5s infinite;

  @keyframes shine {
    to {
      /* move highlight to right */
      background-position: 200% 0, var(--image-pos);
    }
  }
`);
