/*@flow*/
import gql from 'graphql-tag';

export default gql`
  query AttractionQuery($companyId: ID!, $id: ID!) {
    company(id: $companyId) {
      attraction(id: $id) {
        id
        type
        name
        address
        category
        subcategories
        description
        hours
        phone
        url
        photos
        perks
        lonlat
        startsAt
        endsAt
        socialMedia
        events {
          id
          name
          description
          startsAt
        }
      }
    }
  }
`;
