/*@flow*/
import createBrowserHistory from 'history/createBrowserHistory';
// import createHashHistory from 'history/createHashHistory';
import withQuery from 'history-query-enhancer';
import qs from 'qs';
import { type HistoryOpts as BrowserHistoryOpts } from 'history/createBrowserHistory';
// import { type HistoryOpts as HashHistoryOpts } from 'history/createHashHistory';

export default (props: BrowserHistoryOpts) => {
  let history;
  // if (process.env.__PROD__) {
  //   history = createHashHistory(((props: any): HashHistoryOpts));
  // } else {
  history = createBrowserHistory(((props: any): BrowserHistoryOpts));
  // }
  const transformer = {
    parse: search =>
      qs.parse(search, { arrayLimit: 100, ignoreQueryPrefix: true }),
    stringify: qs.stringify,
  };
  return withQuery(transformer)(history);
};
