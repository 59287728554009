/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { lifecycle, type HOC } from 'recompose';

type InputPropsT = {| src: string, width: number, height: number |};
type EnhancedPropsT = {| ...InputPropsT, isLoaded: boolean |};

const Enhancer: HOC<EnhancedPropsT, InputPropsT> = lifecycle({
  componentDidMount() {
    // Async load the image. When image is loaded, set isLoaded flag.
    const img = new Image();
    img.onload = () => {
      this.setState({ isLoaded: true });
    };
    img.onerror = () => this.setState({ hasError: true });
    img.src = this.props.src;
  },
});

const Component = (props: EnhancedPropsT) => {
  const commonStyle = `
    margin: 0 10px 0 0;
    height: inherit;
    border-radius: 8px;
    scroll-snap-align: center;
  `;

  const cardStyle = `
    background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.8) 50%,
        rgba(255, 255, 255, 0) 100%
      ),
      linear-gradient(rgba(204, 204, 204, 0.5), rgba(204, 204, 204, 0.2));

    background-repeat: no-repeat;

    --highlight-dim: 50px ${props.height}px;
    --image-dim: 100% ${props.height}px;
    background-size: 50px ${props.height}px, var(--image-dim);

    --highlight-pos: -100% 0;
    --image-pos: 0px 0px;
    background-position: var(--highlight-pos), var(--image-pos);

    animation: shine 2.5s infinite;
    animation-timing-function: ease-in;

    @keyframes shine {
      to {
        /* move highlight to right */
        background-position: 200% 0, var(--image-pos);
      }
    }

    ${commonStyle}

    width: ${props.width}px;
    height: ${props.height}px;
  `;

  if (props.isLoaded) {
    return <img alt="demo" src={props.src} css={css(commonStyle)} />;
  }
  return <div css={css(cardStyle)} />;
};

export default Enhancer(Component);
