/*@flow*/
import React from 'react';
import ButtonStack from 'comps/App/ButtonStack';
import styled from '@emotion/styled';
import Loading from 'comps/App/Loading';
import { type FiltersQuery } from 'queries/__generated__/FiltersQuery';
import { type withQueryProps } from './withQuery';

const WhiteOverlay = styled.div(`
  position: absolute;
  background: rgba(255, 255, 255, 0.4);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`);

const withLoading = Comp => props => {
  if (!props.data.loading) return <Comp {...props} />;
  if (props.items.length > 0) {
    // Add an overlay
    return (
      <div style={{ position: 'relative' }}>
        <WhiteOverlay />
        <Comp {...props} />
      </div>
    );
  }
  return <Loading show />;
};

function InapplicableSelections(props) {
  const notInItems = v => props.items.indexOf(v) === -1;
  const inapplicable = props.selected.filter(notInItems);
  if (inapplicable.length === 0) return null;

  return (
    <div>
      You also have the following filters:{' '}
      {inapplicable.map((v, i) => (
        <button className="btn btn-sm" key={i}>
          {v}
        </button>
      ))}
    </div>
  );
}

export default (props: withQueryProps<FiltersQuery>) => {
  const Comp = withLoading(ButtonStack);
  return (
    <React.Fragment>
      {props.title}
      <Comp {...props} />
      <InapplicableSelections items={props.items} selected={props.selected} />
    </React.Fragment>
  );
};
