/*@flow*/
import React from 'react';
import styled from '@emotion/styled';

export type ButtonStackPropsT<ItemT = string> = {
  items: ItemT[],
  selected: ItemT[],
  update: Function,
  onMore: Function,
  onSelect: ItemT => void,
  itemLimit?: number,
  showMore: boolean,
  styles?: Object,
};

const Button = styled.button(
  props => `
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: ${props.isSelected ? 'var(--primary)' : 'white'};
  color: ${props.isSelected ? 'white' : 'var(--primary)'};
  border-color: ${props.isSelected ? 'white' : 'var(--primary)'};
  &:focus { box-shadow: none !important; }
`,
  props => props.styles || {}
);

export default function ButtonStack<StackItem: string>(props: {
  items: StackItem[],
  selected: StackItem[],
  onSelect: StackItem => void,
  onMore?: Function,
  itemLimit?: number | void,
  styles?: Object,
}): React$Node {
  const { itemLimit, onMore = function() {} } = props;
  let more;
  let { items } = props;

  if (itemLimit) {
    if (items.length > itemLimit) {
      more = (
        <button className="btn btn-link" key={9999} onClick={onMore}>
          See more...
        </button>
      );
      items = props.items.slice(0, itemLimit);
    } else if (itemLimit > 4 && itemLimit === items.length) {
      more = (
        <button className="btn btn-link" key={9999} onClick={onMore}>
          See less...
        </button>
      );
    }
  }

  const buttons = items
    .map((item, i) => {
      const isSelected = props.selected.includes(item);
      return (
        <Button
          key={i}
          onClick={() => props.onSelect(item)}
          isSelected={isSelected}
          className="btn"
          styles={props.styles}
        >
          {item}
        </Button>
      );
    })
    .concat([more].filter(Boolean));
  if (buttons.length === 0) return null;
  return <div className="button-stack">{buttons}</div>;
}
