/*@flow*/
import { useContext } from 'react';
// $FlowFixMe
import { __RouterContext as RouterContext } from 'react-router';

/**
 * A public hook to access the imperative API
 */
export default function useRouter() {
  return useContext(RouterContext);
}
