/*@flow*/
import remap from 'lib/remap';
import shallowEqual from 'lib/shallowEqual';
import { useEffect, useContext, useMemo, useRef, useReducer } from 'react';
import { StoreContext } from 'redux-react-hook';

class MissingProviderError extends Error {
  constructor() {
    super(
      'redux-react-hook requires your Redux store to be passed through ' +
        'context via the <StoreContext.Provider>'
    );
  }
}

function memoizeSingleArg<AT, RT>(fn: (arg: AT) => RT): (arg: AT) => RT {
  let value: RT;
  let prevArg: AT;

  return (arg: AT) => {
    if (prevArg !== arg) {
      prevArg = arg;
      value = fn(arg);
    }
    return value;
  };
}

export default (mapper: Object = {}, defaultState: Object = {}) => {
  const store = useContext(StoreContext);
  if (!store) {
    throw new MissingProviderError();
  }
  const m = remap(mapper);
  // We don't keep the derived state but call mapState on every render with current state.
  // This approach guarantees that useMappedState returns up-to-date derived state.
  // Since mapState can be expensive and must be a pure function of state we memoize it.
  const memoizedMapState = useMemo(() => memoizeSingleArg(m), [m]);

  const state = store.getState();
  const derivedState = memoizedMapState(state);

  // Since we don't keep the derived state we still need to trigger
  // an update when derived state changes.
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  // Keep previously commited derived state in a ref. Compare it to the new
  // one when an action is dispatched and call forceUpdate if they are different.
  const lastStateRef = useRef(derivedState);

  const memoizedMapStateRef = useRef(memoizedMapState);

  useEffect(() => {
    lastStateRef.current = derivedState;
    if (memoizedMapStateRef) {
      memoizedMapStateRef.current = memoizedMapState;
    }
  });

  useEffect(() => {
    let didUnsubscribe = false;

    // Run the mapState callback and if the result has changed, make the
    // component re-render with the new state.
    const checkForUpdates = () => {
      if (didUnsubscribe) {
        // Don't run stale listeners.
        // Redux doesn't guarantee unsubscriptions happen until next dispatch.
        return;
      }

      const fn = memoizedMapStateRef.current;
      if (typeof fn === 'function') {
        const newDerivedState = fn(store.getState());

        if (!shallowEqual(newDerivedState, lastStateRef.current)) {
          forceUpdate();
        }
      }
    };

    // Pull data from the store after first render in case the store has
    // changed since we began.
    checkForUpdates();

    // Subscribe to the store to be notified of subsequent changes.
    const unsubscribe = store.subscribe(checkForUpdates);

    // The return value of useEffect will be called when unmounting, so
    // we use it to unsubscribe from the store.
    return () => {
      didUnsubscribe = true;
      unsubscribe();
    };
  }, [store]);

  return { ...defaultState, ...derivedState };
};
