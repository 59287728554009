/*@flow*/
import React from 'react';
import PresentedBy from 'comps/App/PresentedBy';
import RightArrow from 'comps/App/RightArrow';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import Navbar from 'comps/App/Navbar';
import Logo from 'comps/App/Logo';
import Beta from 'comps/App/Beta';

const Container = styled.div`
  display: grid;
  grid-template-rows: 30px 170px 170px 170px 1fr 100px;
  align-items: center;

  padding: 0px 30px;

  .section {
    display: grid;
    align-items: center;
    grid-template-columns: 90% 10%;
    grid-column-gap: 10px;

    height: 100%;
    color: white;

    & > div:nth-of-type(1) {
      border-bottom: solid 1px #ccc;
      height: inherit;
      display: grid;
      align-items: center;
    }

    & > div:nth-of-type(2) {
      text-align: center;
    }

    h1 {
      font-size: calc(2rem);
    }
    h4 {
      font-size: calc(1.5rem * 0.9);
    }
  }

  footer {
    text-align: center;
    a {
      color: white;
    }
  }
`;

export default () => (
  <>
    <Beta />
    <Navbar fixed textColor="white" children={<span />} />
    <Logo style={{ paddingTop: 15 }} />
    <Container>
      <div className="separator" />
      <Link className="section" to="/filters?dest=/find">
        <div>
          <div>
            <h1>SEARCH</h1>
            <h4>Look up specific places</h4>
          </div>
        </div>
        <div>
          <RightArrow />
        </div>
      </Link>
      <Link className="section" to="/events">
        <div>
          <div>
            <h1>EVENTS</h1>
            <h4>Check out upcoming events</h4>
          </div>
        </div>
        <div>
          <RightArrow />
        </div>
      </Link>
      <Link className="section" to="/jobs">
        <div>
          <div>
            <h1>JOBS</h1>
            <h4>Find Jobs in the Neighborhood</h4>
          </div>
        </div>
        <div>
          <RightArrow />
        </div>
      </Link>
      <PresentedBy />
    </Container>
  </>
);
