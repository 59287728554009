/*@flow*/
import * as I from 'immutable';
import { state } from 'store/ops';

export default (key: string) => {
  const m = { Tenant: 'businesses', Event: 'events' };
  return state('filters.type', I.List(['Tenant']))
    .map(v => m[v])
    .reduce((s, k) => {
      return state(`filters.${k}.${key}`, I.List()).concat(s);
    }, I.List())
    .toJS();
};
