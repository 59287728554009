/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
// $FlowFixMe
import { ReactComponent as Compass } from './brownsvillenow_logo.svg';
import PresentedBy from 'comps/App/PresentedBy';
import instruction from './qr_code.png';
import scanMe from './scan_me2.png';

const Container = styled.div`
  * {
    color: white;
  }
  max-width: 800px;
  margin: 0 auto;

  padding: 60px;
  text-align: center;

  display: grid;
  align-items: center;
  grid-template-rows: 30px auto 40px;
  grid-row-gap: 20px;
`;

const Title = styled.div`
  font-family: Avenir-Light;
  font-weight: bold;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
`;

const Light = styled.span`
  font-family: Avenir-Light;
  font-weight: 100;
`;

export default () => (
  <Container>
    <Title>
      <Light>WELCOME TO</Light> <b>BROWNSVILLE</b> <Light>NOW</Light>
    </Title>
    <div>
      <div>
        <div
          css={css(`
          position: relative;
        `)}
        >
          <Compass />
          <div
            css={css(`
            position: absolute;
            left: 0; right: 0; bottom: 0; top: 0;
            text-align: center;
            padding-top: 40px;
            padding-bottom: 40px;

            * { color: var(--yellow); }
            font-size: 1.7rem;
          `)}
          >
            <img src={scanMe} alt="Scan me" style={{ width: 100 }} />
          </div>
        <br />
        <img src={instruction} alt="instruction" style={{ width: 100 }} />
        <p>Use your phone’s camera to scan the code.</p>
        </div>
      </div>
    </div>
    <div>
      <PresentedBy />
    </div>
  </Container>
);
