/*@flow*/
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as I from 'immutable';
import type { EventMap } from '../';

mapboxgl.accessToken =
  'pk.eyJ1IjoiY2l0aWVzZW5zZSIsImEiOiJpcXhSRkdjIn0.5UdfHKjDqOusDF0CUNaQhw';

type Config = {|
  style: string,
  center: [number, number],
  zoom: number,
  attributionControl: boolean,
|} & $Shape<EventMap>;

export default class Atlas {
  map: any;
  config: Config;

  enabled = false;

  static create = () => new Atlas();

  enable(el: HTMLElement, config: Config): Promise<*> {
    if (this.isEnabled()) return Promise.resolve();
    this.config = config;
    const map = new mapboxgl.Map({
      ...config,
      container: el,
    });
    this.map = map;
    this.enabled = true;

    const { events = {} } = this.config;
    Object.entries(events).forEach(([key, fn]) => this.map.on(key, fn));

    return new Promise(resolve => this.map.once('load', resolve));
  }

  update(newConfig: Config) {
    if (!I.is(I.fromJS(newConfig.center), I.fromJS(this.config.center))) {
      this.map.jumpTo({ center: newConfig.center });
    }
    if (newConfig.resize !== this.config.resize) {
      this.map.resize();
    }
    this.config = newConfig;
  }

  disable() {
    if (!this.isEnabled()) return;
    this.enabled = false;

    const { events = {} } = this.config;
    Object.entries(events).forEach(([key, fn]) => this.map.off(key, fn));
  }

  isEnabled() {
    return this.enabled;
  }
}
