/*@flow*/
export default {
  mono: [[0, 'lime'], [600, 'green'], [1200, 'blue'], [1800, 'purple']],
  rainbow: [
    [0, 'red'],
    [400, 'orange'],
    [800, 'yellow'],
    [1200, 'green'],
    [1600, 'blue'],
    [2000, 'indigo'],
    [2400, 'violet'],
  ],
  altColor: [
    [150, '#f54e5e'],
    [900, '#f9886c'],
    [1500, '#f1f075'],
    [2100, '#56b881'],
    [2700, '#3887be'],
    [3450, '#4a4a8b'],
  ],
  pendleton: [
    [150, '#eae49a'],
    [300, '#e3ce4f'],
    [600, '#eeab50'],
    [900, '#ec8353'],
    [1200, '#c88e9c'],
    [1500, '#b0517d'],
    [1800, '#375b97'],
  ],
};
