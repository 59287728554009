/*@flow*/
import React from 'react';
import useTitle from 'lib/useTitle';
import { Link } from 'react-router-dom';
import Navbar from 'comps/App/Navbar';
import styled from '@emotion/styled';
import licImg1 from './pngs/brownsvillenow_img1.png';
import licImg2 from './pngs/brownsvillenow_img2.png';
import licImg3 from './pngs/brownsvillenow_img3.png';
import PresentedBy from 'comps/App/PresentedBy';
import useRouter from 'lib/useRouter';

const Page = styled.div({
  width: '100vw',
  overflow: 'hidden',
});

const Container = styled.div(`
  padding: 30px 40px;
`);

const About = () => {
  useTitle('BrownsvilleNow - About');
  const { history } = useRouter();
  const goBack = () => {
    history.goBack();
  };

  const hrStyles = {
    width:"50%",
    textAlign:"center",
    marginLeft:"0"
  }
  return (
    <Page>
      <Navbar
        textColor="var(--blue)"
        backBtn={
          <button onClick={goBack} css={{ padding: '5px 10px' }}>
            <i className="fa fa-angle-left" />
          </button>
        }
      >
        <Link style={{ marginTop: 2, display: 'inline-block' }} to="/">
        BrownsvilleNow
        </Link>
      </Navbar>
      <img style={{ width: '100%' }} src={licImg1} alt="lic" />
      <Container>
        <h1>What is BrownsvilleNow?</h1>
        <PresentedBy withColor />
        <p style={{ marginTop: 10 }}>
        BrownsvilleNow is a smart sign system and mobile site that helps you explore all that Brownsville’s
        central commercial district has to offer – from unique local businesses to upcoming community events
        to vibrant street art installations.
        </p>
      </Container>
      <img style={{ width: '100%' }} src={licImg2} alt="lic" />
      <Container>
        <h2>How to use BrownsvilleNow</h2>
        <ol>
          <li>
          Using your smartphone’s camera, scan the QR code found on the BrownsvilleNow signs located 
          around the neighborhood. This will get you to the BrownsvilleNow mobile site.
          </li>
          <li>
          Once on the mobile site, you can {' '}
            <Link to="/events">search for events to check out</Link>,{' '}
            <Link to="/filters?dest=%2Ffind">find a specific business</Link>, or{' '}
            <Link to="/explore">browse the BrownsvilleNow map</Link> available
            within walking distance.
          </li>
          <li>Learn about special deals and promotions at local businesses and identify upcoming events within the district. </li>
        </ol>
        <div style={{ textAlign: 'center', marginBottom: 20 }}>
          <img style={{ width: '100%' }} src={licImg3} alt="lic" />
        </div>
        <h3>Search Local</h3>
        <p>
          BrownsvilleNow was created and is managed by the Pitkin Avenue Business Improvement District, 
          a non-profit Brownsville-based organization. The goal of the Brownsville Now program is to attract 
          more residents and visitors to Brownsville’s central commercial district by improving the accessibility 
          and visibility of Brownsville businesses, community-based events and cultural destinations.
        </p>
        <p>
          BrownsvilleNow is currently being offered as a pilot program. If you have
          ideas for how to make the program even stronger,{' '}
          <a
            href="https://www.pitkinavenue.nyc/">please click here to send us your feedback!
            </a>
        </p>
        <p>
          Want to have your business or event listed on BrownsvilleNow?{' '}
          <a
            href="mailto:oliver@pitkinavenue.nyc"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here to get in touch.
          </a>
        </p>
        <p>
          <hr style={hrStyles}></hr>
        </p>
        <p>
          The site is funded in part by NYC Department of Small Business Services Neighborhood 360 Program.
        </p>
        <p>
          The BrownsvilleNow logo and smart signs were designed by Layman Lee. The signs were fabricated by 
          Brownsville’s Cab Signs located at 38 Livonia Avenue. 
        </p>
      </Container>
    </Page>
  );
};

export default About;
