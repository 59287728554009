/*@flow*/
import gql from 'graphql-tag';

export default gql`
  query AttractionsQuery($companyId: ID!, $filters: FiltersInput!) {
    company(id: $companyId) {
      attractions(filters: $filters) {
        id
        name
        lonlat
        photo
        category
        type
        startsAt
        endsAt
        address
      }
      venues(filters: $filters) {
        id
      }
    }
  }
`;
