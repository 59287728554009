// Mock localStorage

export function createStorage() {
  let s = {},
    noopCallback = () => {},
    _itemInsertionCallback = noopCallback;

  const hasOwn = Object.prototype.hasOwnProperty;

  Object.defineProperty(s, 'setItem', {
    get: () => {
      return (k, v) => {
        k += '';
        if (!hasOwn.call(s, k)) {
          _itemInsertionCallback(s.length);
        }
        s[k] = v + '';
      };
    },
  });
  Object.defineProperty(s, 'getItem', {
    get: () => {
      return k => {
        k += '';
        if (hasOwn.call(s, k)) {
          return s[k];
        }
        return null;
      };
    },
  });
  Object.defineProperty(s, 'removeItem', {
    get: () => {
      return k => {
        k += '';
        if (hasOwn.call(s, k)) {
          delete s[k];
        }
      };
    },
  });
  Object.defineProperty(s, 'clear', {
    get: () => {
      return () => {
        Object.keys(s).forEach(k => {
          if (hasOwn.call(s, k)) {
            delete s[k];
          }
        });
      };
    },
  });
  Object.defineProperty(s, 'length', {
    get: () => {
      return Object.keys(s).length;
    },
  });
  Object.defineProperty(s, 'key', {
    value: k => {
      let key = Object.keys(s)[k];
      return !key ? null : key;
    },
  });
  Object.defineProperty(s, 'itemInsertionCallback', {
    get: () => {
      return _itemInsertionCallback;
    },
    set: v => {
      if (!v || typeof v !== 'function') {
        v = noopCallback;
      }
      _itemInsertionCallback = v;
    },
  });
  return s;
}

export default createStorage;

export const polyfill = () => {
  const global = window;
  Object.defineProperty(global, 'localStorage', {
    value: createStorage(),
  });
  Object.defineProperty(global, 'sessionStorage', {
    value: createStorage(),
  });
};
