/*@flow*/
import React from 'react';
// $FlowFixMe
import { ReactComponent as Logo } from './logo_home.svg';

export default (props: { style?: Object } = {}) => (
  <div className="text-center" {...props}>
    <Logo />
  </div>
);
