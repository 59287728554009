/*@flow*/
/** @jsx jsx */
import { useEffect } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import ScrollToTop from 'comps/App/ScrollToTop';
import Router from 'comps/App/Router';
import Sidebar from 'comps/App/Sidebar';
import QuerySync from 'comps/App/QuerySync';
import DataSync from 'comps/App/DataSync';
import * as commands from 'store/commands';
import { jsx, css } from '@emotion/core';
// ----- Pages -----
import Find from 'comps/Pages/Find';
import About from 'comps/Pages/About';
import Jobs from 'comps/Pages/Jobs';
import Home from 'comps/Pages/Home';
import Events from 'comps/Pages/Events';
import Explore from 'comps/Pages/Explore';
import Filters from 'comps/Pages/Filters';
import Favorites from 'comps/Pages/Favorites';
import Feedback from 'comps/Pages/Feedback';
// $FlowFixMe
import { ReactComponent as Symbols } from 'images/symbols.svg';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import client from 'lib/apollo';
import store from 'store';
import { ApolloProvider as ApolloHooksProvider } from 'react-apollo-hooks';
import { StoreContext } from 'redux-react-hook';
import useRouter from 'lib/useRouter';

const NoMatch = () => {
  const { location } = useRouter();
  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
      <p>
        <Link to="/">Go home</Link>
      </p>
    </div>
  );
};

const url = (process.env: any).PUBLIC_URL;

const App = () => {
  const { location } = useRouter();
  useEffect(() => {
    commands.sendPageView({
      page_title: document.title,
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return (
    <div
      className="App"
      css={css(`
      @font-face {
        font-family: Avenir;
        src: url('${url}/font/Avenir.ttc');
      }

      @font-face {
        font-family: Avenir;
        font-weight: normal;
        src: url('${url}/font/Avenir.ttc'),
             url('${url}/font/Avenir-Medium.woff2') format('woff2'),
             url('${url}/font/Avenir-Medium.woff') format('woff');
      }

      @font-face {
        font-family: myfont;
        font-weight: bold;
        src: url('${url}/font/Avenir.ttc'),
             url('${url}/font/Avenir-Heavy.woff2') format('woff2'),
             url('${url}/font/Avenir-Heavy.woff') format('woff');
      }

      font-family: Avenir, sans-serif;
  `)}
    >
      {process.env.__DEV__ && <QuerySync queryPath="ui" storePath="ui" />}
      <DataSync namespace="__lic_local__" storePath="Cache" />
      <Sidebar />
      <Switch>
        <Route exact path="/filters" component={Filters} />
        <Route path="/find" component={Find} />
        <Route path="/pages" component={Find} />
        <Route exact path="/explore" component={Explore} />
        <Route exact path="/events" component={Events} />
        <Route exact path="/about" component={About} />
        <Route exact path="/jobs" component={Jobs} />
        <Route exact path="/favorites" component={Favorites} />
        <Route exact path="/feedback" component={Feedback} />
        <Route exact path="/" component={Home} />
        <Route component={NoMatch} />
      </Switch>
    </div>
  );
};

const Wrapper = ({ children }: { children: React$Node }) => (
  <StoreContext.Provider value={store}>
    <ReduxProvider store={store}>
      <ApolloProvider client={client}>
        <ApolloHooksProvider client={client}>{children}</ApolloHooksProvider>
      </ApolloProvider>
    </ReduxProvider>
  </StoreContext.Provider>
);

const Component = () => (
  <Wrapper>
    <Router>
      <ScrollToTop>
        <Route path="/" component={App} />
        <Symbols />
      </ScrollToTop>
    </Router>
  </Wrapper>
);

export default Component;
