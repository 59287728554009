/*@flow*/
import type { StateT } from 'store/types';

const showError = (state, path, kmm) => {
  if (!state.hasIn(path.split('.')) && !kmm) {
    const msg = 'WARNING: [getIn] key path not found: `' + path + '`';
    console.error(msg);
  }
};
const isNil = value => value === undefined || value === null;

// -----------------------------------------

type KeyPathT = string | void;
type GetOptT = $Shape<{
  kmm: boolean,
}>;
type GetInT = (ps: KeyPathT, fallback: any, opts: ?GetOptT) => any;

// getIn obtains the value of state at keyPath in the container
//
// Usage:
//    getIn(store.getState)('foo.bar.zeee')
//
// Arguments:
//  - stateGet: () => StateT
function getIn(stateGet: () => StateT): GetInT {
  return (path: KeyPathT, fallback: any, opts: ?GetOptT) => {
    const state = stateGet();
    opts = opts || {};
    // When path is empty, return full state.
    if (!path) return state;
    const { kmm = true } = opts;
    showError(state, path, kmm);
    // $FlowFixMe
    const value = state.getIn(path.split('.'));
    return isNil(value) ? fallback : value;
  };
}

export default getIn;
