/*@flow*/
import mkIcon from './mkIcon';
import { type AttractionsQuery_company_attractions } from 'queries/__generated__/AttractionsQuery';
import { type LonLatT } from 'store/types';

type RetT = {
  id: ID,
  lonlat: LonLatT,
  properties: {
    icon: string,
    title: ?string,
    inFocus: boolean,
    category: any,
  },
};

export default function mkPoints(
  focusId: string,
  attractions: (AttractionsQuery_company_attractions & { isVenue: boolean })[]
) {
  const checkFocus = id => String(focusId) === String(id);
  return attractions.map<RetT>(v => {
    const { id, lonlat, name, type, category, isVenue } = v;
    const inFocus = checkFocus(id);
    const icon = mkIcon({
      type,
      inFocus,
      isVenue,
      category,
    });
    return {
      id,
      lonlat,
      properties: { icon, title: name, inFocus, category },
    };
  });
}
