/*@flow*/
import React, { useEffect, useState, createRef } from 'react';
import Deck from './core/Deck';
import Atlas from './core/Atlas';
import Spinner from './ui/Spinner';
import LineIndicator from './ui/LineIndicator';
import Canvas from './ui/Canvas';
import * as I from 'immutable';
import hash from 'lib/hash';

type PropsT = {| decks: Object[], config: Object, loading?: boolean |};
export type EventMap = { [key: string]: Function };

const Container = (props: PropsT) => {
  const { config, decks, loading } = props;
  const [atlas] = useState(Atlas.create());
  const [isMounted, setMounted] = useState(false);
  const [isReady, setReady] = useState(false);
  const el = createRef();

  const d: any = I.fromJS(decks);
  const stableDecks = d.map(v => v.delete('events'));
  const isConfigurable = () => {
    const v: any = I.fromJS(config);
    return el.current && !v.isEmpty();
  };

  useEffect(() => {
    setMounted(true);
    return () => atlas && atlas.disable();
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (isReady) {
      atlas.update(config);
    } else if (isConfigurable()) {
      const ref = el.current;
      if (!ref) return;
      atlas.enable(ref, config).then(() => {
        !isReady && setReady(true);
      });
    }
  }, [atlas, config, hash(stableDecks), isReady, loading, isMounted]);

  if (!atlas) return null;

  return (
    <Canvas className="atlas-canvas">
      {isReady && loading && <LineIndicator map={atlas.map} />}
      <div className="atlas-map" ref={el} />
      {isReady && <Spinner map={atlas.map} loading={Boolean(loading)} />}
      {isReady &&
        decks.map(c => <Deck config={c} map={atlas.map} key={c.id} />)}
    </Canvas>
  );
};

export default Container;
