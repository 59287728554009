/*@flow*/
import React, { useState, useEffect } from 'react';
import { type ItemT } from '../types';
import VirtualList from 'comps/App/VirtualList';
import outerWidth from 'lib/outerWidth';
import useStoreState from 'lib/useStoreState';
import styled from '@emotion/styled';
import getItemWidth from './getItemWidth';
import Item from './Item';
import Overlay from './Overlay';
import * as I from 'immutable';

const getListWidth = () => (document.body ? document.body.offsetWidth - 10 : 0);
const getListHeight = () => {
  const node = document.body;
  return node ? node.offsetHeight : 0;
};

type PropsT = {
  items: ItemT[],
};

const Container = styled.div(`
  height: 100%;
  position: relative;
`);

const Border = styled.div({
  height: 'inherit',
  padding: '5px 10px',
});

const Component = (props: PropsT) => {
  const { items } = props;
  const { clickedItemId, focusId } = useStoreState({
    clickedItemId: 'Map.clickedItem.id',
    focusId: 'Focus.id',
  });

  const [scrollToIndex, setScrollToIndex] = useState(0);
  const [itemIsLoading] = useState(false);

  // When clicked item is not in items, reset scroller index.
  useEffect(() => {
    const ix = I.List(items).findIndex(
      v => String(v.id) === String(clickedItemId)
    );
    if (ix === -1) {
      setScrollToIndex(0);
    } else {
      setScrollToIndex(ix);
    }
  }, [clickedItemId, items]);

  if (items.length === 0 || outerWidth(document.body) === 0) return null;

  const renderItem = ({ index, style }) => {
    const item = items[index];
    return (
      <div key={index} style={style}>
        <Item
          {...item}
          key={item.id}
          inFocus={String(focusId) === String(item.id)}
          isLoading={itemIsLoading}
        />
      </div>
    );
  };

  return (
    <Container className="scroller">
      <Overlay setScrollToIndex={setScrollToIndex} items={items} />
      <Border>
        <VirtualList
          width={getListWidth()}
          height={getListHeight()}
          scrollDirection="horizontal"
          itemCount={items.length}
          itemSize={getItemWidth}
          resetScroll={clickedItemId || 0}
          scrollToIndex={scrollToIndex}
          renderItem={renderItem}
        />
      </Border>
    </Container>
  );
};

export default Component;
