/*@flow*/
import { useEffect } from 'react';
import useRouter from 'lib/useRouter';

export default function ScrollToTop(props: { children: React$Node }) {
  const { location } = useRouter();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return props.children;
}
