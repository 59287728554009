/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import NonEmptyArray from 'lib/NonEmptyArray';
import Present from 'lib/Present';
import EmptyObject from 'lib/EmptyObject';
import Blank from 'lib/Blank';
import React from 'react';
import Days, { type Day } from './Days';
import Column from './Column';

const Phone = props => {
  if (!props.phone) return null;
  return (
    <Column>
      <React.Fragment>
        <div>
          <i className="fa fa-phone" />
        </div>
        <div>
          <a href={`tel:${props.phone}`}>{props.phone}</a>
        </div>
      </React.Fragment>
    </Column>
  );
};

const SocialMedia = ({ values }) => {
  if (EmptyObject.is(values)) return null;
  const items = Object.keys(values)
    .map((key, i) => {
      const url = values[key];
      const name = key.replace('_link', '');
      if (!url) return null;
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          key={i}
          className="mr-3 ml-3"
        >
          <i className={'fab fa-' + name} />
        </a>
      );
    })
    .filter(Boolean);
  if (Blank.is(items)) return null;

  return (
    <div
      className="mt-3 d-flex justify-content-center"
      css={css(`
        font-size: 1.5rem;
    `)}
    >
      {items}
    </div>
  );
};

const cleanUrl = url =>
  (url || '')
    .replace(/https?:\/\//, '')
    .replace(/www\./, '')
    .replace(/\/(.+)$/, '')
    .replace(/\/$/, '');

export default (props: {
  address: ?string,
  phone: ?string,
  url: ?string,
  days: Day[],
  socialMedia: { [key: string]: string },
}) => {
  if (!Present.is(Object.values(props).filter(Present.is))) return null;
  return (
    <div
      css={css`
        margin: 10px 0 10px;

        & hr {
          border-color: var(--blue);
        }

        button {
          outline: 0 !important;
        }

        .fa,
        .fab {
          color: var(--blue);
        }

        .line-1 {
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: solid 1px #ccc;

          display: grid;
          justify-items: center;
          align-items: center;

          &:last-child {
            border-bottom: none;
          }

          & > div {
            width: 100%;
          }
        }

        .line-2 {
          display: grid;
          grid-template-columns: 40% 1fr;
          grid-column-gap: 10px;
          align-items: center;
        }
      `}
    >
      <div>
        {props.phone && (
          <div className="line-1">
            <div>
              <Phone phone={props.phone} />
            </div>
          </div>
        )}
        {props.address && (
          <div className="line-1">
            <Column>
              <React.Fragment>
                <div>
                  <i className="fa fa-map-marker" />
                </div>
                <div>{props.address}</div>
              </React.Fragment>
            </Column>
          </div>
        )}
        {props.url && (
          <div className="line-1">
            <Column>
              <React.Fragment>
                <div>
                  <i className="fa fa-globe" />
                </div>
                <div>
                  <a href={props.url} target="_blank" rel="noopener noreferrer">
                    {cleanUrl(props.url)}
                  </a>
                </div>
              </React.Fragment>
            </Column>
          </div>
        )}
        {NonEmptyArray.is(props.days) && (
          <div className="line-1">
            <div>
              <Days days={props.days} />
            </div>
          </div>
        )}
        <SocialMedia values={props.socialMedia} />
      </div>
      <hr />
    </div>
  );
};
