/*@flow*/
import { applyMiddleware } from 'redux';
import reducer from './reducer';
import createStore from './createStore';
import thunkMiddleware from './middleware/thunk';
import * as I from 'immutable';

const init = I.fromJS({
  Map: {
    token:
      'pk.eyJ1IjoiY2l0aWVzZW5zZSIsImEiOiJpcXhSRkdjIn0.5UdfHKjDqOusDF0CUNaQhw',
  },
  companyId: 420,
  filters: { limit: 0 },
});

const middlewares = applyMiddleware(thunkMiddleware);
const store = createStore(reducer, init, middlewares);
export default store;
