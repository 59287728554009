/*@flow*/
import type { ActionT, StateT } from './types';
import * as c from './constants';
import setIn from 'lib/setIn';

export default (state: StateT, action: ActionT) => {
  const { type, payload } = action;
  switch (type) {
    case c.SET_RECORD:
      if (payload) {
        state = setIn(state)(payload);
      }
      break;
    default:
      break;
  }
  return state;
};
