/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import formatDate from 'comps/Pages/Events/formatDate';

export default (props: {
  type: string,
  startsAt: ?string,
  endsAt: ?string,
}) => {
  if (!props.startsAt) return null;
  if (props.type !== 'Event') return null;
  return (
    <div
      css={css`
        padding: 10px 0;
        position: relative;
        text-align: center;
      `}
    >
      {formatDate(props)}
    </div>
  );
};
