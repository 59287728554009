/*@flow*/
import * as I from 'immutable';

export default (v: ?Object) => {
  if (
    v === undefined ||
    v === null ||
    typeof v === 'string' ||
    typeof v === 'number'
  ) {
    return v;
  }
  if (typeof v.hashCode === 'function' || I.Map.isMap(v)) return v.hashCode();
  // $FlowFixMe
  return I.fromJS(v || {}).hashCode();
};
