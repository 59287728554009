/*@flow*/
/** @jsx jsx */
import { con, state } from 'store/ops';
import * as I from 'immutable';
import cx from 'classnames';
import { type HOC } from 'recompose';
import { jsx, css } from '@emotion/core';
import * as commands from 'store/commands';

type InputPropsT = {| id: string, type: string |};
type EnhancedPropsT = { ...InputPropsT, inList: boolean };

const Enhancer: HOC<EnhancedPropsT, InputPropsT> = con({
  inList: props =>
    state('Cache.Favorites', I.List()).includes(String(props.id)),
});

const Component = (props: EnhancedPropsT) => {
  if (props.type !== 'Tenant') return null;
  const text = props.inList ? 'Saved' : 'Save';

  return (
    <div
      css={css(`
      position: absolute;
      bottom: 5px;
      left: 5px;
    `)}
    >
      <button
        css={css(`
          color: white;
          background: rgba(0, 0, 0, 0.4);
          padding: 3px 5px;
        `)}
        onClick={e => {
          e.stopPropagation();
          commands.toggleFavorite(props.id);
        }}
      >
        <i
          className={cx('fa-bookmark', {
            fas: props.inList,
            far: !props.inList,
          })}
        />{' '}
        {text}
      </button>
    </div>
  );
};

export default Enhancer(Component);
