/*@flow*/
import React from 'react';
import { nestedFlip } from 'lib/nested';
import { type HOC } from 'recompose';

const mkReducer = (config, props) => (s, key) => {
  const n = nestedFlip(props);

  let c = config[key];
  let value;
  // e.g.
  //  remapProps({items: 'data.company.attractions'})
  //
  if (typeof c === 'string') {
    value = n(c);
  } else if (Array.isArray(c)) {
    // Combines items in all paths.
    //
    // e.g.
    //    remapProps({items: [['path.to.foo', 'path.to.bar'], ['A', 'B']]})
    //
    // TODO: test
    if (Array.isArray(c[0])) {
      value = [];
      c[0].forEach(keyPath => value.push(...n(keyPath, c[1])));
    } else if (typeof c[0] === 'function') {
      // TODO: test
      value = n(c[0](), c[1]);
    } else if (c[0]) {
      value = n(...c);
    } else {
      value = c[1];
    }
  } else if (typeof c === 'function') {
    value = c(props);
  } else {
    throw new Error(`Unhandled argument type: ${typeof c}`);
  }
  return { ...s, [key]: value };
};

function remapProps<BaseAdd: Object, Enhanced>(
  propsMapper: ((ownerProps: Enhanced) => BaseAdd) | BaseAdd
): HOC<{ ...$Exact<Enhanced>, ...BaseAdd }, Enhanced> {
  return Component => props => {
    const config =
      typeof propsMapper === 'function' ? propsMapper(props) : propsMapper;
    const reducer = mkReducer(config, props);
    const newProps = Object.keys(config).reduce(reducer, {});
    return <Component {...props} {...newProps} />;
  };
}

export default remapProps;
