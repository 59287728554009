/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import AttractionsQueryDef from 'queries/Attractions';
import Loading from 'comps/App/Loading';
import mkFileStackUrl from 'lib/mkFileStackUrl';
import useRouter from 'lib/useRouter';
import Blank from 'lib/Blank';
import useCachedQuery from 'lib/useCachedQuery';
import useStoreState from 'lib/useStoreState';
import * as I from 'immutable';

type InputPropsT = {|
  category: ?string,
  subcategories: ?Array<?string>,
  id: ID,
|};

// Use subcategories as a filter to fetch similar places from the company
// data
export default (props: InputPropsT) => {
  const { id, category, subcategories } = props;
  const { companyId } = useStoreState({ companyId: 'companyId' });
  const { history } = useRouter();
  const filters = {
    businesses: {
      category: category,
      subcategories: subcategories,
    },
    limit: 5,
  };
  const variables = { filters, companyId };
  const { data, loading: isLoading } = useCachedQuery(AttractionsQueryDef, {
    variables,
  });
  const items = I.getIn(data, ['company', 'attractions'], []).filter(
    v => String(v.id) !== String(id)
  );

  if (Blank.is(subcategories)) return null;
  if (items.length === 0 && !isLoading) return null;
  if (isLoading) return <Loading show />;

  const renderItem = (item, index) => {
    return (
      <div
        key={index}
        css={css(`
          height: 100%;
          margin-right: 10px;
        `)}
        onClick={() => {
          setTimeout(() => {
            // Visit page
            const node = document.querySelector('.attractions.show');
            if (!node) return;
            node.scrollTop = 0;
            history.push(`/pages/${item.id}`);
          }, 10);
        }}
      >
        <div
          css={css(`
            color: white;
            border: solid 1px #ccc;
            border-radius: 5px;
            display: inline-block;
            width: 320px;
            height: inherit;
            padding: 10px;
            ${
              item.photo
                ? `background: url(${mkFileStackUrl(
                    item.photo,
                    320,
                    180
                  )}) no-repeat left top;`
                : 'background: var(--primary-gradient);'
            }
          `)}
        >
          <div
            css={css(`
            background: rgba(0, 0, 0, 0.4);
            padding: 2px 5px;
            display: inline-block;
          `)}
          >
            {item.name}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <hr css={css(`border-color: var(--blue);`)} />
      <div
        css={css`
          margin-top: 10px;
          margin-bottom: 10px;
          color: var(--blue);
          padding: 0px 0px 20px 10px;
        `}
      >
        <h2
          css={css(
            `font-size: 1.3rem;font-weight: bold;font-family: Avenir-Heavy; color: var(--blue);`
          )}
          className="mb-2 pl-3 pr-3"
        >
          Similar places
        </h2>
        <div
          css={css(`
          height: 180px;
          width: 100%;
          overflow-y: hidden;
          overflow-x: scroll;

          display: flex;
          -webkit-overflow-scrolling: touch;
          scroll-snap-type: x mandatory;
          &::-webkit-scrollbar {
            width: 0px; /* remove scrollbar space */
            background: transparent; /* optional: just make scrollbar invisible */
            display: none;
          }
          &::-webkit-scrollbar-thumb {
            display: none;
          }
          & > div {
            scroll-snap-align: start;
          }
      `)}
        >
          {items.map(renderItem)}
        </div>
      </div>
    </Fragment>
  );
};
