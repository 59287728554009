/*@flow*/
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState, useEffect, createRef, useRef, useMemo } from 'react';
import SizeAndPositionManager, {
  type ItemSize,
} from './SizeAndPositionManager';
import {
  ALIGNMENT,
  DIRECTION,
  SCROLL_CHANGE_REASON,
  marginProp,
  oppositeMarginProp,
  positionProp,
  scrollProp,
  sizeProp,
} from './constants';
import smoothScroll from 'lib/smoothScroll';

export { DIRECTION as ScrollDirection } from './constants';

export type ItemPosition = 'absolute' | 'sticky';

function parseCss(value) {
  // regex to parse lengths
  let regex = /(\d+)(px|%)/g;
  let parsed = { value: 0, unit: 'px' };
  let result = regex.exec(value);

  // if regexp fails, value is null
  if (result !== null) {
    parsed = { value: parseInt(result[1], 10), unit: result[2] };
  }

  return parsed;
}

function getWidth(obj) {
  return obj.offsetWidth;
}

const getPx = (value: number | string, obj: ?HTMLElement): number => {
  if (!obj) return 0;

  if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string') {
    const declaration = parseCss(value);
    if (declaration.unit === 'px') {
      return declaration.value;
    } else if (declaration.unit === '%') {
      return (getWidth(obj) / 100) * declaration.value;
    }
  }
  return 0;
};

export interface ItemStyle {
  position: ItemPosition;
  top?: number;
  left: number;
  width: string | number;
  height?: string | number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  zIndex?: number;
}

type StyleCache = {
  [id: number]: ItemStyle,
};

export interface ItemInfo {
  index: number;
  style: ItemStyle;
}

export interface RenderedRows {
  startIndex: number;
  stopIndex: number;
}

export type Props = {
  className?: string,
  estimatedItemSize?: number,
  height: number,
  width: string | number,

  itemCount: number,
  itemSize: ItemSize,
  overscanCount?: number,
  scrollOffset?: number,
  scrollToIndex?: number,
  resetScroll?: ID,
  scrollToAlignment?: $Values<typeof ALIGNMENT> | void,
  scrollDirection?: $Values<typeof DIRECTION> | void,
  stickyIndices?: number[],
  style?: $Shape<CSSStyleDeclaration>,
  onItemsRendered?: RenderedRows => void,
  onScroll?: (offset: number, event: Event) => void,
  renderItem(itemInfo: ItemInfo): React$Node,
};

export interface State {
  offset: number;
  scrollChangeReason: $Values<typeof SCROLL_CHANGE_REASON>;
}

const STYLE_WRAPPER: $Shape<{
  ...CSSStyleDeclaration,
  WebkitOverflowScrolling: string,
}> = {
  overflow: 'auto',
  willChange: 'transform',
  WebkitOverflowScrolling: 'touch',
};

const STYLE_INNER: $Shape<CSSStyleDeclaration> = {
  position: 'relative',
  width: '100%',
  minHeight: '100%',
};

const STYLE_ITEM: {
  position: $PropertyType<ItemStyle, 'position'>,
  top: $PropertyType<ItemStyle, 'top'>,
  left: $PropertyType<ItemStyle, 'left'>,
  width: $PropertyType<ItemStyle, 'width'>,
} = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
};

const STYLE_STICKY_ITEM = {
  ...STYLE_ITEM,
  position: 'sticky',
};

function getSize(index: number, itemSize: Function | number | number[]) {
  if (typeof itemSize === 'function') {
    return itemSize(index);
  }

  return Array.isArray(itemSize) ? itemSize[index] : itemSize;
}

export default (props: Props) => {
  const {
    estimatedItemSize,
    itemCount,
    itemSize,
    scrollOffset,
    scrollToAlignment,
    scrollToIndex,
    resetScroll,
    onScroll,
    scrollDirection = DIRECTION.VERTICAL,
    height,
    overscanCount = 3,
    renderItem,
    onItemsRendered,
    stickyIndices,
    style,
    width = '100%',
  } = { scrollToIndex: 0, ...props };

  const rootNodeRef = createRef();
  const styleCache = useRef<StyleCache>({});
  const [scrollChangeReason, setScrollChangeReason] = useState(
    SCROLL_CHANGE_REASON.REQUESTED
  );
  const [offset, setOffset] = useState(scrollOffset || 0);

  const getEstimatedItemSize = () => {
    return (
      estimatedItemSize || (typeof itemSize === 'number' && itemSize) || 50
    );
  };

  const itemSizeGetter = (index: number) => getSize(index, itemSize);

  const sizeAndPositionManager = useMemo(
    () =>
      new SizeAndPositionManager({
        itemCount,
        itemSizeGetter,
        estimatedItemSize: getEstimatedItemSize(),
      })
  );

  const getOffsetForIndex = () => {
    let index = scrollToIndex;
    if (scrollToIndex < 0 || scrollToIndex >= itemCount) {
      index = 0;
    }

    const value = props[sizeProp[scrollDirection]] || 0;

    const rootNode = rootNodeRef.current;
    const containerSize = getPx(value, rootNode);

    return sizeAndPositionManager.getUpdatedOffsetForIndex({
      align: scrollToAlignment,
      containerSize,
      currentOffset: offset || 0,
      targetIndex: index,
    });
  };

  const componentCleanup = () => {
    const rootNode = rootNodeRef.current;
    if (rootNode) {
      rootNode.removeEventListener('scroll', handleScroll);
    }
    window.removeEventListener('beforeunload', componentCleanup);
  };

  useEffect(() => {
    window.addEventListener('beforeunload', componentCleanup);

    // see: https://developers.google.com/web/updates/2016/06/passive-event-listeners
    const opts = {
      passive: true,
    };
    const rootNode = rootNodeRef.current;
    rootNode && rootNode.addEventListener('scroll', handleScroll, opts);

    if (scrollOffset != null) {
      scrollTo(scrollOffset);
    } else if (scrollToIndex != null) {
      scrollTo(getOffsetForIndex());
    }

    return () => {
      componentCleanup();
    };
  }, []);

  useEffect(() => {
    sizeAndPositionManager.updateConfig({
      itemSizeGetter,
    });
  }, [itemSize]);

  useEffect(() => {
    sizeAndPositionManager.updateConfig({
      itemCount,
      estimatedItemSize: getEstimatedItemSize(),
    });
  }, [itemCount, estimatedItemSize]);

  useEffect(() => {
    recomputeSizes();
  }, [itemCount, itemSize, estimatedItemSize]);

  useEffect(() => {
    setOffset(scrollOffset || 0);
    setScrollChangeReason(SCROLL_CHANGE_REASON.REQUESTED);
  }, [scrollOffset]);

  useEffect(() => {
    if (typeof scrollToIndex !== 'number') return;
    setOffset(getOffsetForIndex());
    setScrollChangeReason(SCROLL_CHANGE_REASON.REQUESTED);
  }, [
    scrollToIndex,
    scrollToAlignment,
    itemCount,
    itemSize,
    estimatedItemSize,
  ]);

  useEffect(() => {
    setOffset(0);
    setScrollChangeReason(SCROLL_CHANGE_REASON.REQUESTED);
  }, [resetScroll]);

  useEffect(() => {
    scrollTo(offset);
  }, [offset, scrollChangeReason]);

  const scrollTo = (value: number) => {
    const dest = { [positionProp[scrollDirection]]: value };
    const rootNode = rootNodeRef.current;
    rootNode && smoothScroll(rootNode, dest);
  };

  const recomputeSizes = (startIndex: number = 0) => {
    styleCache.current = {};
    sizeAndPositionManager.resetItem(startIndex);
  };

  const handleScroll = (event: Event) => {
    const offset2 = getNodeOffset();

    if (
      offset2 < 0 ||
      offset === offset2 ||
      event.target !== rootNodeRef.current
    ) {
      return;
    }

    setOffset(offset);
    setScrollChangeReason(SCROLL_CHANGE_REASON.OBSERVED);

    if (typeof onScroll === 'function') {
      onScroll(offset, event);
    }
  };

  const getNodeOffset = () => {
    const rootNode = rootNodeRef.current;
    // $FlowFixMe
    return rootNode ? rootNode[scrollProp[scrollDirection]] : 0;
  };

  const getStyle = (index: number, sticky: boolean) => {
    const style = styleCache.current[index];

    if (style) {
      return style;
    }

    const { size, offset } = sizeAndPositionManager.getSizeAndPositionForIndex(
      index
    );

    return (styleCache.current[index] = sticky
      ? {
          ...STYLE_STICKY_ITEM,
          [sizeProp[scrollDirection]]: size,
          [marginProp[scrollDirection]]: offset,
          [oppositeMarginProp[scrollDirection]]: -(offset + size),
          zIndex: 1,
        }
      : {
          ...STYLE_ITEM,
          [sizeProp[scrollDirection]]: size,
          [positionProp[scrollDirection]]: offset,
        });
  };

  let containerSize = props[sizeProp[scrollDirection]] || 0;
  // Handle string
  const rootNode = rootNodeRef.current;
  containerSize = getPx(containerSize, rootNode);

  const { start, stop } = sizeAndPositionManager.getVisibleRange({
    containerSize,
    offset,
    overscanCount,
  });
  const items: React$Node[] = [];
  const wrapperStyle = { ...STYLE_WRAPPER, ...style, height, width };
  const innerStyle = {
    ...STYLE_INNER,
    [sizeProp[scrollDirection]]: sizeAndPositionManager.getTotalSize(),
  };

  if (stickyIndices != null && stickyIndices.length !== 0) {
    stickyIndices.forEach((index: number) =>
      items.push(
        renderItem({
          index,
          style: getStyle(index, true),
        })
      )
    );

    if (scrollDirection === DIRECTION.HORIZONTAL) {
      innerStyle.display = 'flex';
    }
  }

  if (typeof start !== 'undefined' && typeof stop !== 'undefined') {
    for (let index = start; index <= stop; index++) {
      if (
        !stickyIndices ||
        (Array.isArray(stickyIndices) && !stickyIndices.includes(index))
      ) {
        items.push(
          renderItem({
            index,
            style: getStyle(index, false),
          })
        );
      }
    }

    if (typeof onItemsRendered === 'function') {
      onItemsRendered({
        startIndex: start,
        stopIndex: stop,
      });
    }
  }

  return (
    <div ref={rootNodeRef} css={wrapperStyle} className="virtual-list">
      <div style={innerStyle}>{items}</div>
    </div>
  );
};
