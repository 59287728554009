/*@flow*/
import { withRouter } from 'react-router';
import { type ContextRouter } from 'react-router-dom';
import { compose, type HOC, lifecycle } from 'recompose';
import * as I from 'immutable';
import { con, set } from 'store/ops';

type InputPropsT = {|
  queryPath: string,
  storePath: string,
|};
type EnhancedPropsT = {| ...InputPropsT, ...ContextRouter |};

//
//
// Usage:
//
//  <QuerySync queryPath='filters' storePath='filters' />
//
const Enhancer: HOC<EnhancedPropsT, InputPropsT> = compose(
  withRouter,
  con(props => [props.storePath]),
  lifecycle({
    componentDidMount() {
      const {
        queryPath: qp,
        storePath: sp,
        location: { query: q2 },
      } = this.props;

      const newQueryValue: any = I.fromJS(q2);
      const newValueAtQueryPath = newQueryValue.getIn(qp.split('.'));
      set(sp, newValueAtQueryPath);
    },
    componentDidUpdate(prevProps) {
      const {
        location: { query: q1 },
      } = prevProps;
      const {
        queryPath: qp,
        storePath: sp,
        location: { query: q2 },
      } = this.props;

      const storedValue = this.props[sp];
      const newQueryValue: any = I.fromJS(q2);

      const storedValueChanged = !I.is(prevProps[sp], this.props[sp]);
      const newValueAtQueryPath = newQueryValue.getIn(qp.split('.'));
      const v: any = I.fromJS(q1);
      const queryValueChanged = !I.is(
        v.getIn(qp.split('.')),
        newValueAtQueryPath
      );

      if (!I.is(storedValue, newQueryValue)) {
        if (storedValueChanged && storedValue) {
          const query = newQueryValue.updateIn(
            qp.split('.'),
            () => storedValue
          );
          this.props.history.push({
            pathname: this.props.location.pathname,
            query: query.toJS(),
          });
        } else if (queryValueChanged && newValueAtQueryPath) {
          set(sp, newValueAtQueryPath);
        }
      }
    },
  })
);
const Component = () => null;
export default Enhancer(Component);
