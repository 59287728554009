/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { LEVEL } from 'store/constants';
import { Link } from 'react-router-dom';
import { con } from 'store/ops';
import { type HOC } from 'recompose';
import cx from 'classnames';

type EnhancedPropsT = { isUp: boolean };
const Enhance: HOC<EnhancedPropsT, *> = con({ isUp: 'Toaster.isUp' });

const HEIGHT = '50px';

const Component = (props: EnhancedPropsT) => {
  return (
    <div
      className={cx('toaster', { up: props.isUp })}
      css={css(`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: ${LEVEL.TOASTER};
        height: 0px;
        & > div {
          transform: translateY(20px);
          transition: transform 400ms;
        }
        &.up > div {
          transform: translateY(-${HEIGHT});
        }
      `)}
    >
      <div
        css={css(
          `
            box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.3);

            margin: 0 auto;
            width: 176px;
            background: white;
            color: black;
            height: ${HEIGHT};
            font-size: 0.825rem;
            text-align: center;
            padding: 7px 5px;
          `
        )}
      >
          Bookmark added.         <br />
        <Link to="/favorites">View your favorites.</Link>
      </div>
    </div>
  );
};
export default Enhance(Component);
