/*@flow*/
import styled from '@emotion/styled';

export default styled.div`
  &::after {
    content: 'Upcoming Events';
    padding: 7px 10px 7px;
    background: #ffe496;
    color: #907373;
    display: block;

    text-align: center;
    font-weight: bold;
    font-size: 0.7rem;

    width: 125px;
  }
  z-index: 99999;
  position: absolute;

  width: 125px;
  height: 34px;

  right: -14px;
  bottom: -10px;
  overflow: hidden;
`;
