/*@flow*/

export default (fn: () => boolean, ms: number = 100): Promise<void> =>
  new Promise(res => {
    const timer = setInterval(() => {
      if (fn()) {
        clearInterval(timer);
        res();
      }
    }, ms);
  });
