/*@flow*/
import styled from '@emotion/styled';
import { LEVEL } from 'store/constants';

export default styled.div(`
  position: absolute;
  top: 56px;
  left: 0%;
  right: 0%;
  bottom: 0%;
  background: white;
  padding: 10px;
  z-index: ${LEVEL.FILTERS_PAGE};
  border-top: solid 1px #efefef;
  .button-stack {
    margin-bottom: 10px;
  }
`);
