/*@flow*/
import { format } from 'date-fns';

const OPTION = { awareOfUnicodeTokens: true };
const pattern1 = 'MMM d';
const pattern2 = 'hh:mm a';

export default (props: { startsAt: ?string, endsAt: ?string }) => {
  const { startsAt, endsAt } = props;
  if (startsAt === undefined || startsAt === null) return '';
  if (endsAt === undefined || endsAt === null) return '';

  const output =
    format(new Date(startsAt), pattern1, OPTION) +
    ', ' +
    [startsAt, endsAt]
      .filter(Boolean)
      .map(v => format(new Date(v), pattern2, OPTION))
      .join(' - ');

  return output;
};
