/*@flow*/
import gql from 'graphql-tag';

export default gql`
  query FavoritesQuery($companyId: ID!, $filters: FiltersInput!) {
    company(id: $companyId) {
      attractions(filters: $filters) {
        id
        name
        lonlat
      }
    }
  }
`;
