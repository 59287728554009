/*@flow*/
import styled from '@emotion/styled';

export default styled.div`
  &::after {
    content: 'Beta';
    padding: 5px 10px;
    background: #ffe637;
    color: #907373;
    width: 200px;
    display: block;
    text-align: center;
    transform: rotate(-45deg);
    font-weight: bold;
  }
  z-index: 99999;
  position: absolute;
  top: 10px;
  left: -75px;
`;
