/*@flow*/
import type { StateT } from 'store/types';

type TargetT = string | [string, any];

const getKeyPath = target => {
  let keyPath = [];
  let fallback;
  if (typeof target === 'string') {
    keyPath = target.split('.');
  } else if (Array.isArray(target)) {
    keyPath = target[0].split('.');
    fallback = target[1];
  } else {
    throw new Error('[remap] `' + target + '` cannot be handled.');
  }
  return [keyPath, fallback];
};

const getValue = (target, state, ownProps) => {
  // Return target result, if target is a function.
  if (typeof target === 'function') return target(ownProps);
  // Parse keyPath and fallback values from target.
  const [keyPath, fallback] = getKeyPath(target);
  // $FlowFixMe
  return state.getIn(keyPath, fallback);
};

type PropsT = { [key: string]: TargetT } | void | string[] | (Object => PropsT);

const parseConfig = (o, ownProps) => {
  if (Array.isArray(o)) {
    return o.reduce((s, k) => ({ ...s, [k]: k }), {});
  } else if (typeof o === 'object') {
    return o;
  } else if (typeof o === 'function') {
    return parseConfig(o(ownProps));
  }
  return {};
};

const remap = (o: PropsT) => (state: StateT, ownProps: Object) => {
  let newO = parseConfig(o, ownProps);

  const reducer = (s, [key, value]) => {
    return {
      ...s,
      [key]: getValue(((value: any): TargetT), state, ownProps),
    };
  };
  return Object.entries(newO).reduce(reducer, {});
};

export default remap;
