/*@flow*/
/** @jsx jsx */
import styled from '@emotion/styled';
import { jsx, css } from '@emotion/core';
import { set } from 'store/ops';
import * as commands from 'store/commands';
import Ridge from './Ridge';
import Loading from 'comps/App/Loading';
import SpatialIndex from 'lib/SpatialIndex';
import * as I from 'immutable';
import Scroller from './Scroller';
import Search from './Search';
import Hammer from 'lib/Hammer';
import { DRAWER_HEIGHT, LEVEL } from 'store/constants';
import { useEffect, useState, useContext } from 'react';
import useStoreState from 'lib/useStoreState';
import EmptyArray from 'lib/EmptyArray';
import hash from 'lib/hash';
import useRouter from 'lib/useRouter';
import MapContext from '../MapContext';

const Background = styled.div(
  props => `
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: ${
    props.isDown ? `calc(-1 * ${DRAWER_HEIGHT.toString()} + 43px)` : '0px'
  };
  transition: bottom 200ms;
  transition-timing-function: ease-out;
  z-index: ${LEVEL.DRAWER};
`
);
const EmptyBg = styled.div(`
  text-align: center;
  background: white;
  width: 100%;
  height: ${DRAWER_HEIGHT.toString()};
  padding: 0px 40px 20px;
  display: grid;
  justify-items: center;
  align-items: center;
  i { display: block; }
`);

const Empty = () => {
  return (
    <EmptyBg>
      <div>
        <i className="fa fa-map-marker-alt" />
        No results. <br />
      </div>
    </EmptyBg>
  );
};

const Container = styled.div(`
  height: ${DRAWER_HEIGHT.toString()};
  background: white;
  overflow-x: scroll;
  overflow-y: hidden;
`);

// -----------------------------------------------------------------

let format = ['.lonlat[0]', '.lonlat[1]', '.lonlat[0]', '.lonlat[1]'];
let spatialIndex = SpatialIndex.create({ capacity: 500, format });

const Drawer = () => {
  let {
    mapCenter,
    clickedItemLonlat,
    isDown,
    filters,
    focusId,
  } = useStoreState({
    mapCenter: 'Map.center',
    clickedItemLonlat: 'Map.clickedItem.lonlat',
    isDown: 'Drawer.isDown',
    filters: 'filters',
    focusId: 'Focus.id',
  });
  const ctx = useRouter();
  const history = ctx.history;
  const { items, isLoading } = useContext(MapContext);
  const [indexedItems, setIndexedItems] = useState(items);
  const [itemsHash, setItemsHash] = useState(0);
  const nothing = EmptyArray.is(indexedItems);

  const theGeom = I.getIn(filters, ['within', 'theGeom'], []);
  useEffect(() => {
    const c = I.List(theGeom);
    if (c.isEmpty()) return;
    // Ensure center is always set by filters[within][theGeom]
    set('Map.clickedItem.lonlat', c);
  }, [hash(theGeom)]);

  useEffect(() => {
    if (window.innerHeight < DRAWER_HEIGHT.value * 2) {
      commands.downDrawer();
    }
    return () => {
      spatialIndex = spatialIndex.clear();
    };
  }, []);

  const [showEmpty, setShowEmpty] = useState(false);
  useEffect(() => {
    let timer = setTimeout(() => {
      setShowEmpty(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const loadIndex = () => {
    spatialIndex = spatialIndex.clear();
    spatialIndex.load(items);
  };
  const reIndexAround = c => {
    if (!c) return;
    // Check the items for which the index is created. Ensure
    // to not reindex if items haven't changed.
    const newHash = hash(items);
    if (newHash === itemsHash) return;
    setItemsHash(newHash);

    loadIndex();
    let ix = spatialIndex.orderBy(c);
    setIndexedItems(ix);
  };

  useEffect(() => {
    // FIXME: we must reindex when the map center changes, but
    // not when the user is scrolling, which breaks the flow of the
    // scroller.
    mapCenter && reIndexAround(mapCenter.toArray());
  }, [hash(mapCenter), hash(items)]);

  useEffect(() => {
    clickedItemLonlat &&
      reIndexAround(clickedItemLonlat.map(v => parseFloat(v)).toArray());
  }, [hash(clickedItemLonlat), hash(items)]);

  useEffect(() => {
    clickedItemLonlat && !mapCenter && set('Map.center', clickedItemLonlat);
  }, [hash(clickedItemLonlat), hash(mapCenter)]);

  const goToFiltersPage = () => {
    const path = history.location.pathname;
    const query = { dest: path, filters: filters.toJS() };
    history.push({ pathname: '/filters', query });
  };

  const showFirstItem = () => {
    const item = indexedItems[0];
    item && commands.showDetails({ id: item.id, lonlat: item.lonlat });
  };

  useEffect(() => {
    !focusId && showFirstItem();
  }, [hash(indexedItems)]);

  return (
    <div
      css={css(`
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: ${LEVEL.DRAWER};
      `)}
    >
      <Hammer
        direction="DIRECTION_VERTICAL"
        onSwipeDown={() => {
          commands.downDrawer();
        }}
        onSwipeUp={() => {
          commands.upDrawer();
        }}
      >
        <Background className="drawer" isDown={isDown}>
          <Ridge onShowFilters={() => goToFiltersPage()} />
          <Container>
            {!isDown && <Search onShowFilters={() => goToFiltersPage()} />}
            {nothing && isLoading && <Loading show />}
            {showEmpty && nothing && !isLoading && <Empty />}
            <Scroller items={indexedItems} />
          </Container>
        </Background>
      </Hammer>
    </div>
  );
};

export default Drawer;
