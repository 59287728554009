/*@flow*/
/** @jsx jsx */
import { jsx } from '@emotion/core';
import cx from 'classnames';
import { con, state } from 'store/ops';
import { type HOC } from 'recompose';
import * as commands from 'store/commands';

type InputPropsT = {| id: string |};
type EnhancedPropsT = {| ...InputPropsT, inList: boolean |};

const Enhancer: HOC<EnhancedPropsT, InputPropsT> = con({
  inList: props => state('Cache.Favorites', []).includes(String(props.id)),
});

const Component = (props: EnhancedPropsT) => {
  return (
    <button
      onClick={() => {
        commands.toggleFavorite(props.id);
      }}
    >
      <i
        className={cx('fa-2x fa-bookmark', {
          fas: props.inList,
          far: !props.inList,
        })}
      />
    </button>
  );
};

export default Enhancer(Component);
