/*@flow*/
/** @jsx jsx */
import styled from '@emotion/styled';
import ItemLoading from './ItemLoading';
import mkFileStackUrl from 'lib/mkFileStackUrl';
import getItemWidth from './getItemWidth';
import getItemHeight from './getItemHeight';
import { jsx, css } from '@emotion/core';
import mkIcon from '../mkIcon';
import { type ItemT } from '../types';
import formatDate from 'comps/Pages/Events/formatDate';
import VenueRibbon from 'comps/App/VenueRibbon';
import TodoButton from './TodoButton';
import * as icons from 'images/markers';
import pascalCase from 'lib/pascalCase';
import useRouter from 'lib/useRouter';

const Shadow = styled.div(
  props => `
  box-shadow: 0 .5rem 1rem ${
    props.inFocus ? 'rgba(0, 0, 0, .45)' : 'rgba(0, 0, 0, .15)'
  };
  position: absolute;
  left: 7px;
  right: 0px;
  top: 5px;
  bottom: 0;
  width: 90%;
`
);

const Card = styled.div(
  `
  -webkit-touch-callout: none;
  user-select: none;
  background: white;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  height: inherit;
`,
  props => ({ width: props.cardWidth, height: props.cardHeight })
);

const Content = styled.div(`
  padding: 12px 8px 8px;
  position: relative;
  z-index: 2;
  width: 90%;
  height: 100%;
`);

const Text = styled.h6(`
  font-size: 1rem;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  padding: 5px 5px 3px;
  display: inline-block;
  margin-right: 33px;
`);

const Image = styled.div(
  props => `
  height: ${props.imgHeight - 2}px;
  width: 93%;
  background: ${
    props.imgSrc
      ? `url(${props.imgSrc}) no-repeat left top`
      : 'var(--primary-gradient)'
  };
  border-radius: 5px;
  ${props.inFocus ? 'border: solid 2px var(--blue);' : ''}
  ${props.isVenue ? 'border: solid 4px #FFE496;' : ''}
  position: absolute;
  z-index: 1;
  left: 0;
  top: 4px;
`
);

const Photo = ({ url, cardWidth, cardHeight, inFocus, isVenue }) => {
  let src;
  // = `https://placeimg.com/${cardWidth}/${cardHeight}/arch/grayscale`;
  if (url) {
    src = mkFileStackUrl(url, cardWidth, cardHeight);
  }
  return (
    <Image
      imgSrc={src}
      imgHeight={cardHeight}
      isVenue={isVenue}
      inFocus={inFocus}
      className="image"
    />
  );
};

const Icon = props => {
  const iconFileName = mkIcon({
    type: props.type,
    category: props.category,
  });
  if (!iconFileName) return null;

  const LoadedIcon = icons[pascalCase(iconFileName)];
  if (!LoadedIcon) return null;

  return (
    <div
      css={css(`
        width: 40px;
        position: absolute;
        top: 10px;
        right: -5px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 30px;
      `)}
    >
      <LoadedIcon />
    </div>
  );
};

const Venue = props => (props.isVenue ? <VenueRibbon /> : null);

const Event = (props: any) => {
  const v: { startsAt: ?string, endsAt: ?string, type: string } = props;

  if (v.type !== 'Event') return null;
  if (!v.startsAt) return null;
  if (!v.endsAt) return null;

  return (
    <div
      css={css(`
    position: absolute;
    bottom: 5px;
    left: 0;
  `)}
    >
      <Text>{formatDate(v)}</Text>
    </div>
  );
};

type InputPropsT = {
  ...$Exact<ItemT>,
  isLoading: boolean,
  isVenue: boolean,
  inFocus: boolean,
};

export default function Item(props: InputPropsT) {
  const { history } = useRouter();
  const width = getItemWidth();
  const height = getItemHeight();

  if (props.isLoading) {
    return (
      <Card>
        <ItemLoading />
      </Card>
    );
  }
  if (!width) return null;

  return (
    <Card
      onClick={() => {
        history.push(`/pages/${props.id}`);
      }}
      cardWidth={width}
      cardHeight={height}
    >
      <Shadow className="clearfix" inFocus={props.inFocus} />
      <Photo
        url={props.photo}
        cardWidth={width}
        cardHeight={height}
        inFocus={props.inFocus}
        isVenue={props.isVenue}
      />
      <Content>
        <Text>{props.name}</Text>
        <Icon {...props} />
        <div />
        <Venue {...props} />
        <Event {...props} />
        <TodoButton id={props.id} type={props.type} />
      </Content>
    </Card>
  );
}
