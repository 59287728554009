/*@flow*/

const mkIcon = ({
  type,
  inFocus,
  isVenue,
  category,
}: {
  type: ?string,
  category: ?string,
  inFocus?: boolean,
  isVenue?: boolean,
}) => {
  if (!type) return '';
  if (type === 'Event') return 'circle-11';
  if (!category) return '';
  const shapeM = {
    'Health & Beauty': 'fitness',
    'Shopping': 'grocery',
    'Food & Drink': 'restaurant',
    'Arts & Culture': 'theater',
    'Neighborhood Amenities': 'fitness',
    'Community & Faith Based Orgs': 'fitness',
  };
  const shape = shapeM[category];
  const style = isVenue ? 'venue' : '';
  const effect = inFocus ? 'selected' : '';
  const size = inFocus ? '' : '';
  const icon = [shape, style, effect, size].filter(Boolean).join('-');
  return icon;
};

export default mkIcon;
