/*@flow*/
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import { compose, withStateHandlers, type HOC } from 'recompose';
import * as I from 'immutable';
import Column from './Column';
import cx from 'classnames';
import { isBefore, isAfter } from 'date-fns';
import EmptyArray from 'lib/EmptyArray';

export type Day = {
  day: number,
  intervals: { opening: string, closing: string }[],
};

const mkDate = str => {
  const date = new Date();
  const reHours = /(^\d+):/;
  const reMinutes = /:(\d+)/;
  const reMeridian = /.{2}$/;
  const oh = str.match(reHours) || [];
  const om = str.match(reMinutes) || [];
  const ampm = str.match(reMeridian) || [];
  let hours = parseInt(oh[1], 10);
  ampm[0] === 'PM' && hours < 12 && (hours += 12);
  const minutes = parseInt(om[1], 10);
  date.setHours(hours);
  date.setMinutes(minutes);
  return date;
};

const checkOpen = (day: ?Day) => {
  if (!day) return false;
  if (EmptyArray.is(day.intervals)) return true;
  const int = day.intervals[0];
  if (!int) return false;
  const now = new Date();
  const opening = mkDate(int.opening);
  const closing = mkDate(int.closing);
  return isAfter(now, opening) && isBefore(now, closing);
};

type InputPropsT = {| days: Day[] |};
type EnhancedPropsT = {
  ...InputPropsT,
  isDown: boolean,
  toggle: Function,
};
const Enhancer: HOC<EnhancedPropsT, InputPropsT> = compose(
  withStateHandlers(
    { isDown: false },
    { toggle: ({ isDown }) => () => ({ isDown: !isDown }) }
  )
);
const Component = (props: EnhancedPropsT) => {
  const today = new Date().getDay();
  const d = I.List(props.days);
  const all = d.update(v => v.slice(today).concat(v.slice(0, today)));
  const visible = props.isDown ? all : [];
  const m = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const day = d.find(v => v.day === today);
  const isOpen = checkOpen(day);

  return (
    <Column>
      <Fragment>
        <div css={css('align-self: start; padding-top: 5px;')}>
          <i className="fa fa-clock" />
        </div>
        <div>
          <div onClick={props.toggle}>
            {isOpen ? 'Open' : 'Closed'}
            {!isOpen && !props.isDown && day && (
              <span> &middot; Opens at {day.intervals[0].opening}</span>
            )}
            {day && (
              <button className="btn btn-link">
                <i
                  className={cx('fa', {
                    'fa-caret-up': props.isDown,
                    'fa-caret-down': !props.isDown,
                  })}
                />
              </button>
            )}
          </div>
          {visible.map((v, i) => (
            <div key={i}>
              <div
                css={css(
                  `
                  display: grid;
                  grid-template-columns: 100px 1fr;
                  grid-column-gap: 5px;
                `
                )}
              >
                <Fragment>
                  <div>{m[v.day]}</div>
                  <div>
                    {v.intervals[0] ? (
                      `${v.intervals[0].opening} - ${v.intervals[0].closing}`
                    ) : (
                      <span>Open 24 Hours</span>
                    )}
                  </div>
                </Fragment>
              </div>
            </div>
          ))}
        </div>
      </Fragment>
    </Column>
  );
};

export default Enhancer(Component);
