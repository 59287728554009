/*@flow*/
import gql from 'graphql-tag';

export default gql`
  query FiltersQuery($companyId: ID!, $filters: FiltersInput!) {
    company(id: $companyId) {
      categories(filters: $filters)
      subcategories(filters: $filters)
      tags(filters: $filters)
      projectCount(filters: $filters)
    }
  }
`;
