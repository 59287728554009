/*@flow*/

type Props = {
  x: number,
  y: number,
  element: EventTarget,
  type: TouchEventTypes,
};

export default function sendTouchEvent(props: Props) {
  const { x, y, element, type } = props;
  // $FlowFixMe
  const touchObj = new Touch({
    identifier: Date.now(),
    target: element,
    clientX: x,
    clientY: y,
    radiusX: 2.5,
    radiusY: 2.5,
    rotationAngle: 10,
    force: 0.5,
  });

  const touchEvent = new TouchEvent(type, {
    cancelable: true,
    bubbles: true,
    touches: [touchObj],
    targetTouches: [],
    changedTouches: [touchObj],
    shiftKey: true,
  });

  element.dispatchEvent(touchEvent);
}
